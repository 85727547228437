import { Text } from '@smu-chile/pkg-unimarc-components'
import { useGetDetailOrder } from '@smu-chile/pkg-unimarc-hooks'

export const TextSecond = () => {
  const { data } = useGetDetailOrder()

  return (
    <>
      <Text textAlign='center'>
        Una vez confirmada la cancelación, te enviaremos un correo a:
      </Text>
      <Text
        fontWeight='medium'
        textAlign='center'
      >
        {data?.clientProfileData?.email}
      </Text>
    </>
  )
}
