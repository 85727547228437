export const UNIPAY_PARENT_CONTENT = {
  WITH_CARDS: '1odsTCW5PuI1h0RTSO9xAK',
  CREDIT_APROVAL: '68yMHZTzwUUAfteWpLv7TO',
  WITHOUT_CREDIT_APROVAL: '4rCCzbyLtLK7GiMiF7BIey'
}

export const UNIPAY_CONTENT = {
  CARD_SUMMARY_ICON: '3xHvqXZOlHKOJSTC4IWmcx',
  SHORTCUTS_OPTIONS: '59mirlQqsX8boaUhrvdsER',
  QUESTIONS_OPTIONS: '5s89NHIBE8zxjkVxlO82SH'
}
