import { IHandleStatusTracking, IWebTracking } from '../interfaces/IShipping'
import { statusRequestReceivedTracking } from './statusRequestReceivedTracking'
import { switchStatusTracking } from './switchStatusTracking'
import { IGetDetailOrderResponse } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IGetOrders'

export const changeStatus = (
  icons: IWebTracking[],
  order: IGetDetailOrderResponse
): IHandleStatusTracking[] => {
  const { address, status } = order

  const iconPicking =
    address?.addressType === 'residential' ? 'Motorcycle' : 'Shop'
  const textPosition3 =
    address?.addressType === 'residential' ? 'En camino' : 'Listo para retiro'

  const statusRequestReceived = [
    'waiting-for-sellers-confirmation',
    'payment-pending',
    'payment-approved',
    'time-for-cancelation',
    'on_order_completed',
    'order_created',
    'approve_payment',
    'authorize-fulfillment'
  ]

  if (statusRequestReceived.includes(status.toLowerCase())) {
    return statusRequestReceivedTracking({
      iconPicking,
      icons,
      textPosition3
    })
  }

  return switchStatusTracking({
    iconPicking,
    icons,
    address,
    status,
    textPosition3
  })
}
