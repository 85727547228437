import { ButtonStatus } from '@smu-chile/pkg-unimarc-components'

/**
 * Returns the measurement unit or an empty string if it's "un".
 * @param {string} unit - Unit string
 * @returns {string} - Formatted unit
 * @example
 * getMeasurementUnit('kg') // 'kg'
 * getMeasurementUnit('un') // ''
 */
export const getMeasurementUnit = (unit: string): string => {
  return unit !== 'un' ? unit : ''
}

/**
 * Returns number of decimal places based on unit type.
 * @param {string} unit - Unit string
 * @returns {number} - Decimal places
 * @example
 * getToFixedValue('kg') // 2
 * getToFixedValue('un') // 0
 */
export const getToFixedValue = (unit: string): number => {
  return unit !== 'un' ? 2 : 0
}

/**
 * Determines number of decimals to show based on quantity * unitMultiplier.
 * @param {number} quantity
 * @param {number} unitMultiplier
 * @param {number} toFixedValue
 * @returns {number}
 * @example
 * getShowDecimals(1.25, 1, 2) // 2
 * getShowDecimals(1.10, 1, 2) // 1
 * getShowDecimals(1, 1, 0) // 0
 */
export const getShowDecimals = (
  quantity: number,
  unitMultiplier: number,
  toFixedValue: number
): number => {
  if (toFixedValue !== 2) return toFixedValue
  const decimals = parseFloat(
    (quantity * unitMultiplier).toFixed(2).split('.')[1]
  )
  return decimals % 10 === 0 ? 1 : 2
}

/**
 * Returns formatted quantity with unit or 0 if quantity is 0.
 * @param {number} quantity
 * @param {number} unitMultiplier
 * @param {number} decimals
 * @param {string} unit
 * @returns {string | number}
 * @example
 * getFormattedQuantity(1, 2, 2, 'kg') // '2.00 kg'
 * getFormattedQuantity(0, 1, 2, 'kg') // 0
 */
export const getFormattedQuantity = (
  quantity: number,
  unitMultiplier: number,
  decimals: number,
  unit: string
): string | number => {
  return quantity !== 0
    ? `${(quantity * unitMultiplier).toFixed(decimals)} ${unit}`
    : 0
}

/**
 * Returns true if price and available quantity are greater than 0.
 * @param {number} price
 * @param {number} availableQuantity
 * @returns {boolean}
 * @example
 * getStockStatus(1000, 3) // true
 * getStockStatus(0, 3) // false
 */
export const getStockStatus = (
  price: number,
  availableQuantity: number
): boolean => {
  return !(price === 0 || availableQuantity === 0)
}

/**
 * Returns the button status based on stock.
 * @param {boolean} isStock
 * @returns {'initial' | 'disabled'}
 * @example
 * getButtonStatus(true) // 'initial'
 * getButtonStatus(false) // 'disabled'
 */
export const getButtonStatus = (isStock: boolean): ButtonStatus => {
  return isStock ? 'initial' : 'disabled'
}

/**
 * Returns formatted string for unit and multiplier or fallback format.
 * @param {string} unit
 * @param {number} multiplier
 * @param {string} format
 * @returns {string}
 * @example
 * getFormat('kg', 1, '1 un') // '1 kg'
 * getFormat('un', 1, '1 un') // '1 un'
 */
export const getFormat = (
  unit: string,
  multiplier: number,
  format: string
): string => {
  return unit === 'kg' ? `${multiplier} ${unit}` : format
}

/**
 * Returns the max quantity based on cart limit and availability.
 * @param {number} cartLimit
 * @param {number} availableQuantity
 * @returns {number}
 * @example
 * getMaxQuantity(5, 10) // 5
 * getMaxQuantity(0, 10) // 10
 */
export const getMaxQuantity = (
  cartLimit: number,
  availableQuantity: number
): number => {
  return cartLimit > 0 && cartLimit <= availableQuantity
    ? cartLimit
    : availableQuantity
}

/**
 * Parses a quantity that can be string or number into a number.
 * @param {string | number} quantity - Quantity to parse
 * @returns {number}
 * @example
 * getParsedQuantity('5') // 5
 * getParsedQuantity(10) // 10
 */
export const getParsedQuantity = (quantity: string | number): number => {
  if (!quantity) return 0
  return typeof quantity === 'number' ? quantity : parseInt(quantity)
}

/**
 * Returns tooltip message when quantity reaches max quantity.
 * @param {number} quantity - Current quantity
 * @param {number} maxQuantity - Max allowed quantity
 * @returns {string}
 * @example
 * formatTooltipText(10, 10) // 'Cantidad disponible.'
 * formatTooltipText(5, 10) // ''
 */
export const formatTooltipText = (
  quantity: number,
  maxQuantity: number
): string => {
  return maxQuantity > 0 && quantity >= maxQuantity
    ? 'Cantidad disponible.'
    : ''
}
