import { useEffect, useState } from 'react'
import {
  BigScreen,
  getGlobalStyle,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import {
  AddToListModal,
  ButtonStatus,
  Column,
  Container,
  Row,
  Spacer
} from '@smu-chile/pkg-unimarc-components'
import {
  dataLayerUniqueTypeEvent,
  IEntriesProps,
  IMdpConfigResponse,
  useDocument
} from '@smu-chile/pkg-unimarc-hooks'
import { InformationOrder, RepeatPurchase, TrackingOrder } from './components'
import { ContainerPages } from 'components/ContainerPages'
import { GoBack } from 'components/GoBack'
import { Products } from './components/Products'
import { ShippingType } from './components/ShippingType'
import { TotalizerResume } from './components/TotalizerResume'
import {
  handleStatusTextOrderDetail,
  handleStatusTracking
} from 'shared/helpers'
import { ModalRepeatPurchase } from './components/ModalRepeatPurchase'
import { PurchaseCTAs } from './components/PurchaseCTAs'
import { getSettingsOrderDetail } from './helper/index'
import { useAddProductToList } from 'shared/hooks'
import { TooltipFeedback } from 'components/TooltipFeedback'
import { TooltipAddOrderToList } from './components/TooltipAddOrderToList'
import { IMembershipDataCF } from 'shared/interfaces/IMembership'
import {
  LoaderBigScreen,
  LoaderSmallScreen
} from 'components/OrdersList/PageOrdersLoaders'
import { IGetDetailOrderResponse } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IGetOrders'

export interface OrderHookProps {
  cfData: IEntriesProps
  data: IGetDetailOrderResponse
  isLoading: boolean
  isMobile: boolean
  mdpConfigResponse: IMdpConfigResponse
  membershipDataCF?: IMembershipDataCF
  paramsApp: string | boolean
  paymentCardImage: Record<string, { url: string }>
  repatPurchaseImage: string
  seller: string | boolean
  ticketNumber: string
}
export const statusCancelPurshase = ['Confirmada']

export const OrderDetail = ({
  cfData,
  data,
  isLoading,
  isMobile,
  mdpConfigResponse,
  paramsApp,
  paymentCardImage,
  repatPurchaseImage,
  ticketNumber
}: OrderHookProps) => {
  const [openModalRepeatPurchase, setOpenModalRepeatPurchase] = useState(false)
  const [openWindowFeedback, setOpenWindowFeedback] = useState(false)
  const [openFeedbackReceipt, setOpenFeedbackReceipt] = useState(false)
  const [loadingButton, setLoadingButton] = useState<ButtonStatus>('initial')
  const { creationDate, sequence, status } = data

  const cartLimitArray = []

  const { addWebviewParamsInUrl, textTooltip } = getSettingsOrderDetail(
    innerWidth,
    openFeedbackReceipt,
    paramsApp,
    status
  )

  const statusCanceledIncomplete = ['Cancelada', 'Rechazada']
  const statusCancelPurshase = ['Confirmada']
  const statusDownloadReceipt = [
    'En camino',
    'En preparación',
    'Entregada',
    'Lista para retiro',
    'Retirada'
  ]
  const statusActivateLoader = [
    'Confirmada',
    'Cancelada',
    'Rechazada',
    'Solicitud recibida'
  ]
  const {
    data: documentData,
    isLoading: isLoadingDocumentData,
    refetch
  } = useDocument({
    reactQuery: {
      refetchOnWindowFocus: false
    },
    document_id: data?.invoiceNumber,
    type: 'bill'
  })

  const {
    createListButton,
    emptyOrangeImage,
    listData,
    notificationAddToList,
    openModalCreateList,
    selectedListName,
    handleAddProductsToList,
    handleCreateNewList,
    handleGoToMyLists,
    handleOnClosed,
    handleOpenMyListsModal,
    setCreateListButton,
    setNotificationAddToList
  } = useAddProductToList()

  useEffect(() => {
    if (isLoading) return

    dataLayerUniqueTypeEvent({
      estado_compra: text?.toLowerCase(),
      id_compra: sequence,
      page: 'mis-compras-detalle-compra'
    })
  }, [isLoading])

  useEffect(() => {
    if (documentData?.data?.status === 404) {
      refetch()
    }
  }, [])

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpenWindowFeedback(false)
      setOpenFeedbackReceipt(false)
    }, 3000)
    return () => {
      clearTimeout(timer)
    }
  }, [openWindowFeedback, openFeedbackReceipt])

  if (isLoading || !data?.sequence) {
    return (
      <>
        <BigScreen>
          <LoaderBigScreen />
        </BigScreen>
        <SmallScreen>
          <LoaderSmallScreen />
        </SmallScreen>
      </>
    )
  }
  const { customColor, text } = handleStatusTextOrderDetail(data)
  const isHideOptionByStatus = statusCanceledIncomplete.includes(text)
  const isEnabledCancelPurshase = statusCancelPurshase.includes(text)
  const isEnabledDownloadReceipt =
    !!statusDownloadReceipt.includes(text) && !!documentData?.data
  const isEnabledIconLoader = statusActivateLoader.includes(text)
  const icons = handleStatusTracking(data)

  const handleOnClosedToast = () => {
    setOpenWindowFeedback(false)
    setOpenFeedbackReceipt(false)
  }

  return (
    <ContainerPages>
      <Row
        position={isMobile ? 'fixed' : 'sticky'}
        top={isMobile ? '9' : '74'}
        zIndex={getGlobalStyle('--z-index-10')}
      >
        <Column
          absoluteDefault='topRight'
          left={isMobile ? '0' : ''}
          margin={isMobile ? '0 auto 0 auto' : ''}
          maxWidth='fit-content'
          position='absolute'
          right={isMobile ? '0' : ''}
          top={isMobile ? '0' : ''}
        >
          <AddToListModal
            createListButton={createListButton}
            emptyOrangeImage={emptyOrangeImage}
            handleAddProductsToList={handleAddProductsToList}
            handleCreateNewList={handleCreateNewList}
            innerWidth={innerWidth}
            isMobile={isMobile}
            listData={listData}
            modalTitle='Agregar compra a Mis listas'
            openModalCreateList={openModalCreateList}
            setCreateListButton={setCreateListButton}
            setOpenModalCreateList={handleOpenMyListsModal}
          />

          {(openWindowFeedback || openFeedbackReceipt) && (
            <TooltipFeedback
              nestedTextComponent={textTooltip as React.ReactElement}
              onClose={handleOnClosedToast}
              text={''}
              type='info'
            />
          )}
        </Column>
      </Row>

      {openModalRepeatPurchase && (
        <ModalRepeatPurchase
          isMobile={isMobile}
          openModalRepeatPurchase={openModalRepeatPurchase}
          repatPurchaseImage={repatPurchaseImage}
          setOpenModalRepeatPurchase={setOpenModalRepeatPurchase}
        />
      )}
      {notificationAddToList && (
        <TooltipAddOrderToList
          createListButton={createListButton}
          handleGoToMyLists={handleGoToMyLists}
          isMobile={isMobile}
          onClose={handleOnClosed}
          paramsApp={paramsApp}
          selectedListName={selectedListName}
          setNotificationAddToList={setNotificationAddToList}
        />
      )}
      <BigScreen>
        <>
          <Container
            alignItems='center'
            backgroundColor={getGlobalStyle('--color-base-white')}
            border={`${getGlobalStyle(
              '--border-width-3xs'
            )} solid ${getGlobalStyle('--color-neutral-gray-divider')}`}
            borderRadius={getGlobalStyle('--border-radius-md')}
            customHeight='auto'
            isWrap
            justifyContent='center'
          >
            <Row
              maxHeight='100px'
              minHeight='100px'
              padding='24px 32px'
            >
              <InformationOrder
                creationDate={creationDate}
                sequence={sequence}
              />
            </Row>
            {/* button repeat purchase*/}
            <Row
              maxHeight='100px'
              minHeight='100px'
              padding='24px 32px'
            >
              <RepeatPurchase
                cartLimitArray={cartLimitArray}
                data={data}
                loadingButton={loadingButton}
                openModalRepeatPurchase={openModalRepeatPurchase}
                paramsApp={paramsApp}
                setLoadingButton={setLoadingButton}
                setOpenModalRepeatPurchase={setOpenModalRepeatPurchase}
                sourcePage='OrderDetails'
                text={text}
                ticketNumber={ticketNumber}
              />
            </Row>
            {/* button repeat purchase*/}
            <Row
              isWrap
              padding='24px 25px'
            >
              <TrackingOrder
                alignItemColumn='center'
                icons={icons}
                status={status}
              />
              <Spacer.Horizontal size={48} />
              <ShippingType
                order={data}
                paymentCardImage={paymentCardImage}
              />
            </Row>
          </Container>
          <Container isWrap>
            <Row
              justifyContent='center'
              maxHeight='100%'
              minHeight='100%'
            >
              <Products
                mdpConfigResponse={mdpConfigResponse}
                order={data}
                text={text}
                ticketNumber={ticketNumber}
              />
            </Row>
          </Container>
          <Spacer.Horizontal size={12} />
          <Container
            alignItems='center'
            backgroundColor={getGlobalStyle('--color-base-white')}
            border={`${getGlobalStyle(
              '--border-width-3xs'
            )} solid ${getGlobalStyle('--color-neutral-gray-divider')}`}
            borderRadius={getGlobalStyle('--border-radius-md')}
            customHeight='auto'
            isWrap
            justifyContent='center'
            padding='6px 0'
          >
            <PurchaseCTAs
              addWebviewParamsInUrl={addWebviewParamsInUrl}
              cfData={cfData}
              documentData={documentData}
              enabledCancelPurshase={isEnabledCancelPurshase}
              enabledDownloadReceipt={isEnabledDownloadReceipt}
              enabledIconLoader={isEnabledIconLoader}
              hideOptionByStatus={isHideOptionByStatus}
              isLoadingDocumentData={isLoadingDocumentData}
              setOpenFeedbackReceipt={setOpenFeedbackReceipt}
              setOpenModalCreateList={handleOpenMyListsModal}
              setOpenWindowFeedback={setOpenWindowFeedback}
              tagInfo={{
                orderState: text?.toLowerCase(),
                sequence: data?.sequence
              }}
              ticketNumber={ticketNumber}
            />
          </Container>

          <Spacer.Horizontal size={12} />
          <Container isWrap>
            <Row
              justifyContent='center'
              maxHeight='100%'
              minHeight='100%'
            >
              <TotalizerResume
                loadingButton={loadingButton}
                order={data}
                setLoadingButton={setLoadingButton}
                text={text}
              />
            </Row>
          </Container>
        </>
      </BigScreen>
      <SmallScreen>
        <>
          {!paramsApp && (
            <GoBack
              eventCategory='volver-mis-compras'
              title='Detalle de compra'
            />
          )}
          <Container
            alignItems='center'
            isWrap
            justifyContent='center'
            padding='0 16px 12px'
          >
            <Spacer.Horizontal size={12} />

            <Container
              backgroundColor={getGlobalStyle('--color-base-white')}
              border={`0.3px solid ${getGlobalStyle(
                '--color-neutral-gray-divider'
              )}`}
              borderRadius={getGlobalStyle('--border-radius-md')}
              isWrap
              padding='12px 0'
            >
              <Row padding='0 16px'>
                <InformationOrder
                  creationDate={creationDate}
                  sequence={sequence}
                />
              </Row>
              <Spacer.Horizontal size={8} />
              <Row isWrap>
                <TrackingOrder
                  alignItemColumn='center'
                  customColorText={customColor}
                  icons={icons}
                  status={status}
                  text={text}
                />
                <Spacer.Horizontal size={16} />
                <ShippingType
                  order={data}
                  paymentCardImage={paymentCardImage}
                />
              </Row>
            </Container>

            <Spacer.Horizontal size={12} />
            <Row justifyContent='center'>
              <Column
                backgroundColor={getGlobalStyle('--color-base-white')}
                border={`${getGlobalStyle(
                  '--border-width-3xs'
                )} solid ${getGlobalStyle('--color-neutral-gray-divider')}`}
                borderRadius={getGlobalStyle('--border-radius-md')}
              >
                <Products
                  mdpConfigResponse={mdpConfigResponse}
                  order={data}
                  text={text}
                  ticketNumber={ticketNumber}
                />
              </Column>
            </Row>
            <Spacer.Horizontal size={12} />
            <Container
              backgroundColor={getGlobalStyle('--color-base-white')}
              border={`0.3px solid ${getGlobalStyle(
                '--color-neutral-gray-divider'
              )}`}
              borderRadius={getGlobalStyle('--border-radius-md')}
            >
              <Row padding='0 18px 0 16px'>
                <PurchaseCTAs
                  addWebviewParamsInUrl={addWebviewParamsInUrl}
                  cfData={cfData}
                  documentData={documentData}
                  enabledCancelPurshase={isEnabledCancelPurshase}
                  enabledDownloadReceipt={isEnabledDownloadReceipt}
                  enabledIconLoader={isEnabledIconLoader}
                  hideOptionByStatus={isHideOptionByStatus}
                  isLoadingDocumentData={isLoadingDocumentData}
                  setOpenFeedbackReceipt={setOpenFeedbackReceipt}
                  setOpenModalCreateList={handleOpenMyListsModal}
                  setOpenWindowFeedback={setOpenWindowFeedback}
                  tagInfo={{
                    orderState: text?.toLowerCase(),
                    sequence: data?.sequence
                  }}
                  ticketNumber={ticketNumber}
                />
              </Row>
            </Container>
            <Spacer.Horizontal size={12} />
            <Row justifyContent='center'>
              <Column
                backgroundColor={getGlobalStyle('--color-base-white')}
                borderRadius={getGlobalStyle('--border-radius-md')}
              >
                <TotalizerResume
                  loadingButton={loadingButton}
                  order={data}
                  setLoadingButton={setLoadingButton}
                  text={text}
                />
              </Column>
            </Row>
          </Container>
          {paramsApp && <Spacer.Horizontal size={96} />}

          <RepeatPurchase
            cartLimitArray={cartLimitArray}
            data={data}
            loadingButton={loadingButton}
            openModalRepeatPurchase={openModalRepeatPurchase}
            paramsApp={paramsApp}
            setLoadingButton={setLoadingButton}
            setOpenModalRepeatPurchase={setOpenModalRepeatPurchase}
            sourcePage='OrderDetails'
            text={text}
            ticketNumber={ticketNumber}
          />
        </>
      </SmallScreen>
    </ContainerPages>
  )
}
