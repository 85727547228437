import {
  Button,
  ButtonProps,
  ButtonStatus,
  Column,
  Container,
  Row
} from '@smu-chile/pkg-unimarc-components'
import {
  currentOrderformInfoTag,
  tagCourierName,
  trigger,
  useOrderForm,
  getParamsInURL,
  useRepeatPurchase
} from '@smu-chile/pkg-unimarc-hooks'
import { totalizerId } from '../TotalizerResume'
import {
  IItemOrder,
  ITotalizerOrder
} from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IGetOrders'
import styles from '../../../Components.module.css'

const TAG_INFO_MESSAGE = 'Mis compras - Repetir Compra'

interface OrderActionDetailProps {
  alignItems?: 'center' | 'start' | 'end' | 'baseline' | 'stretch'
  cartLimitArray?: {
    cartLimit: number
    id: string
  }[]
  customButtonProps?: Partial<ButtonProps>
  items?: IItemOrder[]
  justifyContent?: 'center' | 'start' | 'end' | 'between' | 'around'
  loadingButton: ButtonStatus
  maxWidthColumn?: string
  openModalRepeatPurchase?: boolean
  clickRepeatPurchase?: boolean
  tagInfo: {
    orderState: string
    totalizers: ITotalizerOrder[]
    sequence: string
  }
  widthButton?: string
  setLoadingButton: React.Dispatch<React.SetStateAction<ButtonStatus>>
}

export const OrderActionDetail = ({
  alignItems,
  cartLimitArray = [],
  customButtonProps,
  items,
  justifyContent,
  loadingButton,
  maxWidthColumn,
  tagInfo,
  widthButton,
  setLoadingButton
}: OrderActionDetailProps): React.ReactElement => {
  const { data } = useOrderForm()
  const { handleMutate } = useRepeatPurchase()

  if (!data?.data || !data?.data?.orderFormId || !data) {
    return null
  }

  const tagRepeatOrder = () => {
    const subtotal = {
      name: 'Subtotal',
      value: totalizerId(tagInfo?.totalizers, 'Items')
    }?.value
    const discounts = {
      name: 'Subtotal',
      value: totalizerId(tagInfo?.totalizers, 'Discounts')
    }?.value

    currentOrderformInfoTag({
      context: 'repetir-compra',
      customItems: items,
      session_store: tagCourierName,
      defaultCategory: false,
      estado_compra: tagInfo?.orderState,
      event: 'add_to_cart',
      id_compra: tagInfo.sequence,
      value: subtotal + discounts,
      tagInfo: TAG_INFO_MESSAGE,
      orderform_id: data?.data?.orderFormId
    })
  }

  const handleRepeatOrder = async () => {
    setLoadingButton('loading')
    trigger({
      eventType: 'sendTagInfo',
      data: {
        tagInfo: TAG_INFO_MESSAGE
      }
    })
    const itemsAdded = []
    items?.forEach((item) => {
      let totalQuantity = item?.quantity
      const { brandName, id, image, name } = item
      data?.data?.items?.forEach((element) => {
        if (element?.id == id) totalQuantity += element?.quantity
      })
      const cartLimit = cartLimitArray?.filter((object) => {
        return object.id == id
      })
      let cartLimitToSend = item?.cartLimit
      // Validation to send max quantity if there cartLimit
      const quantityToSend =
        cartLimit.length > 0 &&
        totalQuantity > cartLimitToSend &&
        cartLimitToSend !== 0
          ? cartLimitToSend
          : Number(totalQuantity)
      if (Number.isNaN(cartLimitToSend)) cartLimitToSend = undefined

      itemsAdded.push({
        brandName: brandName,
        cartLimit: cartLimitToSend,
        id: item?.productId,
        image: image,
        name: name,
        netContent: item?.netContent,
        replacedProduct: '',
        quantity: quantityToSend
      })
    })
    tagRepeatOrder()
    const ticketNumber = getParamsInURL('og')
    const seller = getParamsInURL('seller')
    const orderId = ticketNumber + '-' + seller
    handleMutate({ orderId, items: itemsAdded })
  }

  return (
    <Container
      alignItems='end'
      justifyContent='end'
    >
      <Row>
        <Column
          alignItems={alignItems}
          justifyContent={justifyContent}
          maxWidth={maxWidthColumn}
        >
          <Button
            borderRadius='24px'
            customClassName={styles.repeatPurchaseBtn}
            fontWeight='500'
            height='45px'
            label='Repetir compra'
            minWidth={widthButton}
            onClick={() => {
              handleRepeatOrder()
            }}
            size='xs'
            status={loadingButton}
            {...customButtonProps}
          />
        </Column>
      </Row>
    </Container>
  )
}
