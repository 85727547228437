import { useRouter } from 'next/router'
import { LogoutModal } from './components/LogoutModal'
import { LoginModalBrowse } from 'components/LoginModalBrowse'
import {
  useSession,
  useUserWithMembership,
  useCheckUserData
} from '@smu-chile/pkg-unimarc-hooks'
import { useEffect, useState } from 'react'

export const AuthWrapper = () => {
  const router = useRouter()
  const session = useSession()
  const [isLoggedIn, setIsLoggedIn] = useState(true)
  const [shouldCheckUser, setShouldCheckUser] = useState(false)
  const [loginModalStep, setLoginModalStep] = useState<string>(undefined)
  const [isUserMissingData, setIsUserMissingData] = useState(false)

  const {
    data: dataMembership,
    isLoading: isLoadingMembership,
    refetch
  } = useUserWithMembership()

  /**
   * Check if user is still missing data after closing login.
   */
  useCheckUserData(shouldCheckUser, isUserMissingData, setIsUserMissingData)

  const showLogoutModal = router.query.logout === 'true'

  const handleLoginModalClose = () => {
    setShouldCheckUser(true)
    if (session.isLoggedIn) {
      const { pathname, query } = router
      delete router.query.login
      router.replace({ pathname, query }, undefined, { shallow: true })
    } else {
      router.replace(process.env.NEXT_PUBLIC_HOMEURL || '/')
    }
  }

  const handleLogoutModalClose = () => {
    const { pathname, query } = router
    delete router.query.logout
    router.replace({ pathname, query }, undefined, { shallow: true })
  }

  const shouldLoginModalBeOpen = () => {
    return !isLoggedIn || isUserMissingData
  }

  const refetchUser = () => {
    refetch()
  }

  useEffect(() => {
    if (!session?.isLoggedIn && !session?.isLoading) {
      setIsLoggedIn(false)
    }
  }, [session?.isLoggedIn, session?.isLoading])

  useEffect(() => {
    setLoginModalStep(isUserMissingData ? 'missingUserData' : 'login')
  }, [isUserMissingData])

  useEffect(() => {
    if (dataMembership?.code === 'USR-23') {
      setIsUserMissingData(true)
    }
  }, [dataMembership, isLoadingMembership])

  return (
    <>
      <LoginModalBrowse
        defaultFormStep={loginModalStep}
        isOpen={shouldLoginModalBeOpen()}
        onClose={handleLoginModalClose}
        refetchUser={refetchUser}
        router={router}
      />

      {showLogoutModal && (
        <LogoutModal
          onClose={handleLogoutModalClose}
          onLogoutClick={handleLogoutModalClose}
        />
      )}
    </>
  )
}
