import { Container, Spacer } from '@smu-chile/pkg-unimarc-components'
import { Shelf } from './Shelf'
import { IItemOrder } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IGetOrders'

export interface ShelvesProps {
  productsCount?: number
  items?: IItemOrder[]
}

export const Shelves = (props: ShelvesProps): React.ReactElement => {
  let brandNameFinal = ''
  let priceFinal: number
  const productsCount = props.productsCount
  const itemsNormal = props.items ? props.items.slice(0, productsCount) : null

  return (
    <>
      {
        <Container
          isWrap
          margin='20px 0px'
        >
          {itemsNormal.map((shelve: IItemOrder, index) => {
            if (shelve?.brandName) {
              const { brandName } = shelve
              brandNameFinal = brandName
            }
            if (shelve?.brandName) {
              const { brandName } = shelve
              brandNameFinal = brandName
            }
            const { total } = shelve
            priceFinal = total
            return (
              <>
                <Shelf
                  brandName={brandNameFinal}
                  item={shelve}
                  key={shelve.id}
                  latest={index === itemsNormal.length - 1}
                  price={priceFinal}
                />
                <Spacer.Horizontal customSize={8} />
              </>
            )
          })}
        </Container>
      }
    </>
  )
}
