import { useEffect, useState } from 'react'
import {
  IEntriesProps,
  useContentful,
  useGetDetailOrder
} from '@smu-chile/pkg-unimarc-hooks'
import { ORDER_TYPE_BY_ID } from 'shared/constants'
import { IGetDetailOrderResponse } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IGetOrders'

export interface UseNextClaimData {
  orderData: IGetDetailOrderResponse
  isCFLoading: boolean
  isOrderLoading: boolean
  orderError: unknown
  CFData: IEntriesProps
  CFID: string
  setCFID: (id: string) => void
  setCurrentPage: (
    value: string | number | ((val: string | number) => string | number)
  ) => void
}

/**
 * Get contentful data and orderData
 * @param {number} currentPage a number that represent the currentPage in claim flow process
 * @returns {UseNextClaimData} object with { orderData, isOrderLoading, isCFLoading, orderError, CFData }
 */

export const useGetClaimData = (
  currentPage: number,
  setCurrentPage: (
    value: string | number | ((val: string | number) => string | number)
  ) => void
): UseNextClaimData => {
  const {
    data: orderData,
    isLoading: isOrderLoading,
    error: orderError
  } = useGetDetailOrder()

  const ticketNumber = orderData?.orderId?.split('-')[0] || ''
  const [CFID, setCFID] = useState('')

  const {
    data: CFData,
    isLoading: isCFLoading,
    isFetched
  } = useContentful({
    id_contentful: `centroDeAyuda_${ticketNumber}_${currentPage}_${CFID}`,
    options: {
      'sys.contentType.sys.id[in]': 'centroDeAyudaConfig',
      'sys.id[in]': CFID
    },
    reactQuery: {
      enabled: Boolean(ticketNumber) && !isOrderLoading,
      retry: 3,
      staleTime: 1000 * 60 * 5, // for five minutes the information is not outdated
      refetchOnWindowFocus: false
    }
  })

  const validateCFData = (data: IEntriesProps) => {
    if (CFID && !data?.includes && !isCFLoading && isFetched) {
      setCurrentPage(3)
    }
  }

  useEffect(() => {
    validateCFData(CFData)
  }, [CFData])

  useEffect(() => {
    setCFID(CFID || ORDER_TYPE_BY_ID[orderData?.status])
  }, [ticketNumber, CFID, orderData?.orderId?.split('-')[0]])

  return {
    CFData,
    CFID,
    isCFLoading,
    isOrderLoading,
    orderData,
    orderError,
    setCFID,
    setCurrentPage
  }
}
