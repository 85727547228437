import { RefObject, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import {
  Cookies,
  datalayerSimpleEvent,
  eventClick,
  getAddress,
  getCurrentDomain,
  ISafetyValidationProps,
  postCoordsValidate,
  postNewAddress,
  useEvents,
  useLocalAddress,
  useOrderForm,
  useTrigerEvent,
  useUpdateAddress
} from '@smu-chile/pkg-unimarc-hooks'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng
} from 'use-places-autocomplete'
import {
  StoreLocator,
  ButtonStatus,
  TitleProps
} from '@smu-chile/pkg-unimarc-components'
import { Map } from './Map'
import { addressFormat } from 'shared/utils/addressFormated/address'
import { NewAddressData } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/INewAddressData'
import { regions } from '@smu-chile/pkg-unimarc-hooks/utils/info'
import { updateSalesChannel } from 'shared/helpers'

interface AddressMaps {
  city: string
  country: string
  neighborhood: string
  number: string
  state: string
  street: string
  postalCode: string
  place_id: string
}

interface StoreLocatorComponentProps {
  safetyValidationProps: ISafetyValidationProps
  generateOTP: ({
    mapsAddress,
    valueAddressStreetNumber,
    addressRef
  }) => Promise<number>
}

export const StoreLocatorComponent = ({
  safetyValidationProps,
  generateOTP
}: StoreLocatorComponentProps): React.ReactElement => {
  const router = useRouter()
  const [failModal, setFailModal] = useState(false)
  const [mapModal, setMapModal] = useState(false)
  const [errorAddress, setErrorAddress] = useState(false)
  const [streetNumberInput, setStreetNumberInput] = useState(false)
  const [address, setAddress] = useState('')
  const [valueAddressStreetNumber, setValueAddressStreetNumber] = useState('')
  const [errorMapMessage, setErrorMapMessage] = useState('')
  const [addressRef, setAddressRef] = useState('')
  const [addressComments, setAddressComments] = useState('')
  const [buttonStatus, setButtonStatus] = useState('')
  const [errorType, setErrorType] = useState('')
  const [verifiedSc, setSc] = useState('')
  const [userDirection, setUserDirection] = useState(false)
  const [failModalColorTitle, setFailModalColorTitle] = useState('')
  const [failModalButtonText, setFailModalButtonText] = useState('')
  const [failModalContent, setFailModalContent] = useState('')
  const [failModalTitle, setFailModalTitle] = useState('')
  const [titlePosition, setTitlePosition] =
    useState<TitleProps['textAlign']>('center')
  const [contentPosition, setContentPosition] =
    useState<TitleProps['textAlign']>('center')
  const [failModalEvent, setFailModalEvent] = useState('')
  const [addressToDelete, setAddressToDelete] = useState(null)
  const [confirmAddressButtonStatus, setConfirmAddressButtonStatus] =
    useState<ButtonStatus>('disabled')
  const [latMap, setLatMap] = useState(0)
  const [lngMap, setLngMap] = useState(0)
  const [addressList, setAddressList] = useState([])
  const [addressSelected, setAddressSelected] = useState(null)
  const [mapsAddress, setMapsAddress] = useState<AddressMaps | null>(null)
  const { data: orderFormData } = useOrderForm()
  const {
    handleAddressObject,
    data: updateData,
    isSuccess
  } = useUpdateAddress(router)
  const [directionModal, setDirectionModal] = useState(false)
  const [selectedManualAddres, setSelectedManualAddres] = useState(false)
  const [regionList, setRegionList] = useState([])
  const [comunaList, setComunaList] = useState([])
  const [manualAddress, setManualAddress] = useState(null)
  const [saveSalesChannel, setSaveSalesChannel] = useState(false)
  const [sameAddressOnClose, setSameAddressOnClose] = useState(false)
  const { regions: availableStores } = regions
  const { trigger } = useTrigerEvent()
  const {
    suggestions: { data },
    setValue
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: 'cl' },
      types: ['address']
    },
    cache: false
  })

  // to get local address if exist
  const { localOrderForm } = useLocalAddress()

  useEvents({
    eventType: 'storeLocator',
    callBack: ({
      detail: { show, toCC, saveSalesChannel, sameAddressOnClose }
    }) => {
      setDirectionModal(show)
      if (toCC !== undefined) {
        trigger({
          eventType: 'storeLocator',
          data: { show: toCC, saveSalesChannel }
        })
      }
      // if the value is truthy (!!) will convert it to "true" and if is falsy will convert it to "false"
      setSaveSalesChannel(!!saveSalesChannel)
      setSameAddressOnClose(!!sameAddressOnClose)
    }
  })

  const findSelectedStore = addressList.findIndex((addressSelect) => {
    return addressSelect.selected === true
  })

  const handleOnClick = (notClosing = false) => {
    const isNotClosing = typeof notClosing !== 'boolean' ? false : notClosing
    setConfirmAddressButtonStatus('disabled')
    if (saveSalesChannel && !isNotClosing) {
      updateSalesChannel(Number(orderFormData?.data?.salesChannel))
    }
    if (sameAddressOnClose && !isNotClosing) {
      confirmUserAddress(true)
    }
    trigger({
      eventType: 'storeLocator',
      data: { show: !directionModal, saveSalesChannel }
    })
  }

  const handleSelect = async ({
    description,
    refreshMap = true
  }: {
    description: string
    refreshMap?: boolean
  }) => {
    const results = await getGeocode({ address: description })
    const formatedDirection = addressFormat(results)
    const { lat, lng } = await getLatLng(results[0])

    if (formatedDirection.number.length === 0) {
      formatedDirection.number =
        valueAddressStreetNumber && valueAddressStreetNumber?.length > 0
          ? valueAddressStreetNumber
          : 's/n'
      setStreetNumberInput(true)
    }

    if (!formatedDirection.street || formatedDirection.street == '') {
      formatedDirection.street =
        manualAddress && manualAddress?.length > 0 ? manualAddress : 's/n'
    }
    setMapsAddress(formatedDirection)
    const response = await postCoordsValidate({ data: [lng, lat] })
    if (!response.data.error) {
      setSc(response.data.sc.toString())
      setAddress(description)
      setLatMap(lat)
      setLngMap(lng)
      if (refreshMap) {
        handleOnClick()
        setMapModal(!mapModal)
      }
    } else {
      setFailModal(!failModal)
      setFailModalColorTitle(getGlobalStyle('--color-base-black'))
      setFailModalTitle('Dirección sin cobertura de despacho')
      setContentPosition('center')
      setFailModalButtonText('Cambiar dirección')
      setFailModalContent(
        'No podemos despachar a tu dirección. Si quieres continuar comprando cambia tu dirección.'
      )
      refreshMap ? handleOnClick() : setMapModal(!mapModal)
    }

    return { error: response.data.error }
  }

  const handleSelectManualAddress = async () => {
    datalayerSimpleEvent({
      event: 'evento_interactivo',
      eventAction: 'dirección manual',
      eventCategory: 'interacciones en metodo de entrega',
      eventLabel: 'Ingresar dirección manualmente'
    })
    setRegionList(
      availableStores.map((store) => {
        return store.region
      })
    )
    setSelectedManualAddres(true)
    setMapModal(!mapModal)
    handleOnClick(true)
  }

  const regionSelector = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (event.target.value === 'Seleccionar región') return setComunaList([])

    const filterStore = availableStores?.filter((elem) => {
      return elem.region === event.target.value
    })[0]
    return setComunaList(
      filterStore.comunas.map((comuna) => {
        return comuna.name
      })
    )
  }

  const setNewLocation = (lat: number, lng: number) => {
    setLatMap(lat)
    setLngMap(lng)
  }

  const errorModal = () => {
    setFailModal(!failModal)
    setFailModalColorTitle(getGlobalStyle('--color-base-black'))
    setFailModalTitle('Dirección sin cobertura de despacho')
    setContentPosition('center')
    setFailModalButtonText('Cambiar dirección')
    setFailModalContent(
      'No podemos despachar a tu dirección. Si quieres continuar comprando cambia tu dirección.'
    )
    handleOnClick()
    setAddress('')
    setValue('')
  }

  const mapCloseModal = (withTag = false) => {
    withTag &&
      eventClick({
        eventCategory: 'interacciones en metodo de entrega',
        'event-label': 'modal cerrar'
      })
    setMapModal(false)
    setButtonStatus('')
    setManualAddress('')
    setMapsAddress(null)
    setAddress('')
    setAddressRef('')
    setAddressComments('')
    setStreetNumberInput(false)
    setValueAddressStreetNumber('')
    if (errorAddress) {
      setErrorAddress(!errorAddress)
    }
  }

  const mapBackModal = () => {
    eventClick({
      eventCategory: 'interacciones en metodo de entrega',
      'event-label': 'modal atras'
    })
    setAddress('')
    setValue('')
    setButtonStatus('')
    setManualAddress('')
    setMapsAddress(null)
    setAddressRef('')
    setAddressComments('')
    setSelectedManualAddres(false)
    setMapModal(!mapModal)
    handleOnClick(true)
    setStreetNumberInput(false)
    setValueAddressStreetNumber('')
    if (errorAddress) {
      setErrorAddress(!errorAddress)
    }
  }

  const mapErrorAddress = (error: string, type: string) => {
    if (!error) {
      setButtonStatus('')
      setErrorAddress(false)
    }

    if (error) {
      setButtonStatus('disabled')
      setErrorType(type)
      setErrorMapMessage(error)
      setErrorAddress(true)
    }
  }

  const handleNewAddress = async (obj: NewAddressData) => {
    const { city, country, neighborhood, number, state, street } = obj
    const responseNewAddress = await postNewAddress(obj)
    const isError = responseNewAddress?.data['error']

    if (typeof isError === 'boolean' && !isError) {
      handleAddressObject({
        addressId: responseNewAddress.data['data'].addressName,
        addressType: responseNewAddress.data['data'].addressType,
        city: city,
        complement: addressRef,
        country: country,
        geoCoordinates: [lngMap, latMap],
        neighborhood: neighborhood,
        number: number.length === 0 ? valueAddressStreetNumber : number,
        reference: addressComments,
        state: state,
        street: street.length === 0 ? state : street,
        salesChannel: verifiedSc
      })
      return
    }

    safetyValidationProps.handleCloseModal()

    trigger({
      eventType: 'addressChanged',
      data: {
        show: true,
        isError,
        message: 'No pudimos agregar tu dirección, intenta nuevamente.'
      }
    })
  }

  const handleGenerateOTP = async () => {
    const status = await generateOTP({
      mapsAddress,
      valueAddressStreetNumber,
      addressRef
    })

    if (status === 201) {
      setMapModal(false)
    }

    setButtonStatus('')
  }

  const setAddressFromMap = async (): Promise<void> => {
    safetyValidationProps.handleLoadingButton(true)
    if (localOrderForm?.data?.selectedAddresses) return
    const { place_id, number, ...propsMapsAddress } = mapsAddress

    const {
      data: {
        userProfileId,
        clientProfileData: { firstName, lastName }
      }
    } = orderFormData

    handleNewAddress({
      ...propsMapsAddress,
      complement: addressRef,
      geoCoordinate: [lngMap, latMap],
      number: number.length === 0 ? valueAddressStreetNumber : number,
      placeId: place_id,
      receiverName: `${firstName} ${lastName}`,
      reference: addressComments,
      userId: userProfileId
    })
  }

  const handleValidateCoords = async () => {
    setButtonStatus('loading')
    datalayerSimpleEvent({
      event: 'evento_interactivo',
      eventAction: 'dirección manual',
      eventCategory: 'interacciones en metodo de entrega',
      eventLabel: 'Confirmar dirección'
    })
    if (!navigator?.onLine)
      mapErrorAddress(
        'Lo sentimos, no tienes conexión por favor intenta de nuevo.',
        'error'
      )
    else {
      if (buttonStatus === 'loading') return
      setButtonStatus('loading')

      const addressVal = `${mapsAddress?.street}, ${
        mapsAddress?.number?.length === 0 || mapsAddress?.number === 's/n'
          ? valueAddressStreetNumber
          : mapsAddress?.number
      }, ${mapsAddress?.neighborhood}, ${mapsAddress?.state}, ${mapsAddress?.country}`

      const geoCode = await getGeocode({ address: addressVal })
      const { lng, lat } = await getLatLng(geoCode[0])
      const dataLngLat = await postCoordsValidate({ data: [lng, lat] })

      if (dataLngLat?.data?.error) {
        mapCloseModal()
        setFailModal(!failModal)
        setFailModalColorTitle(getGlobalStyle('--color-base-black'))
        setFailModalTitle('Dirección sin cobertura de despacho')
        setContentPosition('center')
        setFailModalButtonText('Cambiar dirección')
        setFailModalContent(
          'No podemos despachar a tu dirección. Si quieres continuar comprando cambia tu dirección.'
        )
        setButtonStatus('')
        return
      }

      // send a client to a validation screen
      handleGenerateOTP()
    }
  }

  const buttonAddressMap = async () => {
    // new flow to validate OTP
    if (!safetyValidationProps?.safetyValidationModal) {
      // validate coords and generate OTP
      return handleValidateCoords()
    }
    // set address after validate OTP when doesn't have products
    return setAddressFromMap()
  }

  useEffect(() => {
    if (updateData) {
      if (!userDirection) {
        if (updateData?.data?.error) {
          mapErrorAddress(
            'Lo sentimos, tuvimos un error al cargar tu información por favor intenta de nuevo.',
            'error'
          )
          setButtonStatus('')
        } else {
          setButtonStatus('')
          mapCloseModal()
        }
      } else {
        if (updateData?.data?.error) {
          setConfirmAddressButtonStatus('disabled')
          throw new Error('Error en el cambio de dirección')
        } else {
          trigger({ eventType: 'storeLocator', data: { show: false } })
          setConfirmAddressButtonStatus('disabled')
        }
      }
    }
  }, [updateData?.data?.error])

  const getAddresId = () => {
    if (orderFormData) {
      return orderFormData?.data?.selectedAddresses?.addressId || ''
    }
    return ''
  }

  useEffect(() => {
    if (!addressToDelete) {
      ;(async function () {
        const userAddress = getAddresId()
        setValue('')
        const response = await getAddress()
        if (response?.data?.length === 0) {
          setAddressList([])
        }
        const addressListFormated = []
        response.data?.forEach((element) => {
          addressListFormated.push({
            addressName: element.addressName,
            addressType: element.addressType,
            city: element.city,
            complement: element.complement,
            country: element.country,
            geoCoordinate: element.geoCoordinate,
            id: element.id,
            neighborhood: element.neighborhood,
            number: element.number,
            postalCode: element.postalCode,
            receiverName: element.receiverName,
            reference: element.reference,
            state: element.state,
            street: element.street,
            selected: userAddress !== '' ? userAddress === element.id : false
          })
        })
        for (let index = 0; index < addressListFormated.length; index++) {
          const addressIterated = addressListFormated[index]
          if (addressIterated.selected) addressSelector(addressIterated, index)
        }
        setAddressList(addressListFormated)
      })()
    }
  }, [directionModal])

  const handleClearInputAddress = (_, ref: RefObject<HTMLInputElement>) => {
    ;(ref.current.children[0].children[0] as HTMLInputElement).value = ''
    setAddress('')
    setValue('')
  }

  const addressOnChangeSearch = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAddress(event.target.value)
    if (event.target.value.length > 2) setValue(event.target.value.toString())
    if (event.target.value.length == 3) {
      eventClick({
        eventCategory: 'interacciones en metodo de entrega',
        eventAction: 'carga modal',
        'event-label': 'digitar nueva direccion'
      })
    }
    if (event.target.value.length === 0) {
      setAddress('')
      setValue('')
    }
  }

  const mapAddressComentsOnClearInput = (
    _,
    ref: RefObject<HTMLInputElement>
  ) => {
    ;(ref.current.children[0].children[0] as HTMLInputElement).value = ''
    setAddressComments('')
  }

  const mapAddressComentsOnChangeSearch = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAddressComments(event.target.value)
  }

  // ojo ref, _
  const mapAddressRefOnClearInput = (_, ref: RefObject<HTMLInputElement>) => {
    ;(ref.current.children[0].children[0] as HTMLInputElement).value = ''
    setAddressRef('')
  }

  const mapAddressOnClearInput = (_, ref: RefObject<HTMLInputElement>) => {
    ;(ref.current.children[0].children[0] as HTMLInputElement).value = ''
    setManualAddress('')
  }

  const mapAddressRefOnChangeSearch = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAddressRef(event.target.value)
  }

  const mapAddressOnChangeSearch = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setManualAddress(event.target.value)
  }

  const mapAddressStreetNumberOnClearInput = (
    _,
    ref: RefObject<HTMLInputElement>
  ) => {
    ;(ref.current.children[0].children[0] as HTMLInputElement).value = ''
    setValueAddressStreetNumber('')
  }

  const mapAddressStreetNumberOnChangeSearch = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValueAddressStreetNumber(event.target.value)
    setMapsAddress({
      ...mapsAddress,
      number: event.target.value
    })
  }

  const buttonLogic = () => {
    if (buttonStatus === 'disabled') return 'disabled'
    if (buttonStatus === 'loading') return 'loading'
    if (mapsAddress?.number?.length > 0) return 'initial'
    if (
      mapsAddress?.number?.length === 0 &&
      valueAddressStreetNumber.length > 0
    )
      return 'initial'
    if (availableStores && manualAddress?.length > 0) return 'initial'
    return 'disabled'
  }

  const addressSelector = (item: object, index: number) => {
    eventClick({
      eventCategory: 'interacciones en metodo de entrega',
      'event-label': 'modal seleccion de radio button'
    })
    if (findSelectedStore !== -1) {
      addressList[findSelectedStore].selected = false
      addressList[index].selected = true
      setAddressList([...addressList])
    } else {
      if (addressList[index]) {
        addressList[index].selected = true
        setAddressList([...addressList])
      }
    }
    setAddressSelected(item)
    setConfirmAddressButtonStatus('initial')
  }

  const changeDeliveryMethod = async () => {
    datalayerSimpleEvent({
      event: 'evento_interactivo',
      eventAction: 'dirección manual',
      eventCategory: 'interacciones en metodo de entrega',
      eventLabel: 'cambiar a retiro en tienda'
    })
    eventClick({
      eventCategory: 'interacciones en metodo de entrega',
      'event-label': 'modal boton cambiar retiro en tienda'
    })
    trigger({ eventType: 'deliverySelector', data: { toStore: true } })
    setDirectionModal(false)
  }

  const markingConfirmButton = async () => {
    datalayerSimpleEvent({
      event: 'evento_interactivo',
      eventAction: 'dirección manual',
      eventCategory: 'interacciones en metodo de entrega',
      eventLabel: 'Confirmar dirección'
    })
  }

  const confirmUserAddress = async (closeSL?: boolean) => {
    const { data } = orderFormData
    const isSameAddress =
      sameAddressOnClose &&
      (data?.selectedAddresses?.addressId === addressSelected?.id ||
        closeSL === true)
    const baseGeoCoodinate = isSameAddress
      ? data?.selectedAddresses?.geoCoordinates
      : addressSelected?.geoCoordinate

    setButtonStatus('loading')
    eventClick({
      eventCategory: 'interacciones en metodo de entrega',
      'event-label': 'boton confirmar direccion guardada'
    })
    setConfirmAddressButtonStatus('loading')
    setUserDirection(true)
    const response = await postCoordsValidate({
      data: baseGeoCoodinate
    })

    if (!response.data.error) {
      if (isSameAddress) {
        handleAddressObject({
          ...data?.selectedAddresses,
          salesChannel: response.data.sc.toString(),
          geoCoordinates: data?.selectedAddresses?.geoCoordinates
        })
      } else {
        handleAddressObject({
          ...addressSelected,
          salesChannel: response.data.sc.toString(),
          addressId: addressSelected.id,
          geoCoordinates: addressSelected.geoCoordinate
        })
      }
    }
  }

  const deleteAddress = async () => {
    if (addressToDelete) {
      setAddressList(
        addressList.filter((i) => {
          return i.id !== addressToDelete.id
        })
      )
      setFailModal(!failModal)
      handleOnClick()
    }
  }

  const openDeleteAddressModal = (item: object) => {
    setAddressToDelete(item)
    setFailModal(!failModal)
    setFailModalColorTitle(getGlobalStyle('--color-primary-red'))
    setFailModalTitle('¿Estás seguro que quieres eliminar esa dirección?')
    setFailModalButtonText('Eliminar')
    setTitlePosition('center')
    setFailModalContent(
      'Si lo eliminas, tendras que volver a agregarla para usarla de nuevo.'
    )
    setFailModalEvent('deleteAddress')
    handleOnClick()
  }

  const handleActionModal = () => {
    if (failModalEvent === 'deleteAddress') deleteAddress()
    setAddress('')
    setValue('')
    setFailModal(!failModal)
    handleOnClick()
  }

  const handleKeepAddress = () => {
    mapCloseModal()
    setFailModal(false)
  }

  useEffect(() => {
    if (isSuccess) {
      const domain = getCurrentDomain()
      Cookies.set('showAddressChangedTooltip', 'true', {
        domain,
        path: '/'
      })
    }
  }, [isSuccess])

  return (
    <StoreLocator
      addressList={
        address
          ? data.map((addressData) => {
              return {
                name: addressData.structured_formatting.main_text,
                city: addressData.structured_formatting.secondary_text,
                activeMap: false,
                failModal: false,
                description: addressData.description
              }
            })
          : []
      }
      addressOnChangeSearch={addressOnChangeSearch}
      addressOnClearInput={handleClearInputAddress}
      addressSelector={addressSelector}
      buttonAddressMap={buttonAddressMap}
      buttonStatus={buttonLogic()}
      changeDeliveryMethod={changeDeliveryMethod}
      comunaList={comunaList}
      confirmAddressButtonStatus={confirmAddressButtonStatus}
      confirmUserAddress={confirmUserAddress}
      contentPosition={contentPosition}
      deleteAddress={openDeleteAddressModal}
      directionModal={directionModal}
      errorAddress={errorAddress}
      errorAddressType={errorType}
      errorMapMessage={errorMapMessage}
      errorModal={errorModal}
      failModal={failModal}
      failModalButtonText={failModalButtonText}
      failModalColorTitle={failModalColorTitle}
      failModalContent={failModalContent}
      failModalEvent={handleActionModal}
      failModalTitle={failModalTitle}
      handleKeepAddress={handleKeepAddress}
      handleOnClick={handleOnClick}
      handleSelectManualAddress={handleSelectManualAddress}
      mapAddressCommentsOnChangeSearch={mapAddressComentsOnChangeSearch}
      mapAddressCommentsOnClearInput={mapAddressComentsOnClearInput}
      mapAddressOnChangeSearch={mapAddressOnChangeSearch}
      mapAddressOnClearInput={mapAddressOnClearInput}
      mapAddressRefOnChangeSearch={mapAddressRefOnChangeSearch}
      mapAddressRefOnClearInput={mapAddressRefOnClearInput}
      mapAddressStreetNumberOnChangeSearch={
        mapAddressStreetNumberOnChangeSearch
      }
      mapAddressStreetNumberOnClearInput={mapAddressStreetNumberOnClearInput}
      mapBackModal={mapBackModal}
      mapCloseModal={() => {
        mapCloseModal(true)
      }}
      mapModal={mapModal}
      markingConfirmButton={markingConfirmButton}
      regionList={regionList}
      regionSelector={regionSelector}
      safetyValidationProps={safetyValidationProps}
      selectedAddress={address}
      selectedManualAddres={selectedManualAddres}
      streetNumberInput={streetNumberInput}
      titlePosition={titlePosition}
      userAddressList={addressList}
      validateAddress={handleSelect}
      valueAddressComments={addressComments}
      valueAddressRef={addressRef}
      valueAddressStreetNumber={valueAddressStreetNumber}
      valueManualAddress={manualAddress}
      valueSearch={address}
    >
      <Map
        coordsError={mapErrorAddress}
        location={{ latMap, lngMap }}
        setNewLocation={setNewLocation}
      />
    </StoreLocator>
  )
}
