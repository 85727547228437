import {
  Container,
  Picture,
  Row,
  Spacer,
  Text
} from '@smu-chile/pkg-unimarc-components'
import { KLAP_PAY_NAMES } from 'shared/helpers/paymentSystems'

export interface PropsCard {
  paymentName: string
  finalUrl: string
}

export const KlapPayment = ({
  paymentName,
  finalUrl
}: PropsCard): React.ReactElement => {
  const getKlapType = () => {
    if (
      paymentName === KLAP_PAY_NAMES[0] ||
      paymentName === KLAP_PAY_NAMES[1]
    ) {
      return 'Débito'
    }
    return 'Crédito'
  }

  return (
    <Container
      isWrap
      padding='10px 0px'
    >
      <Row alignItems='center'>
        <Picture
          height='24px'
          src={finalUrl}
          width='auto'
        />
        <Spacer.Vertical size={8} />
        <Container>
          <Row alignItems='center'>
            <Text fontSize='md'>{getKlapType()}</Text>
          </Row>
        </Container>
      </Row>
    </Container>
  )
}
