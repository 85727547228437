import React, { useRef } from 'react'
import Image from 'next/future/image'
import router from 'next/router'
import { Product } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IProducts'
import { AvailableProduct } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IIntelligenceSearch'
import { ICoupon } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/ICoupon'
import {
  formatProductUrl,
  isValidArrayWithData
} from '@smu-chile/pkg-unimarc-hooks'
import { statusPromotionsV2 } from '@smu-chile/pkg-unimarc-components/helpers/promotionsOfShelvesV2'
import { MemoShelf, Row } from '@smu-chile/pkg-unimarc-components'
import { handleChange } from 'components/OrdersList/OrdersBigScreen'
import { useUpsellingShelves } from './hooks/useUpsellingShelves'

export interface CouponModalProps extends Partial<ICoupon> {
  couponLabel?: string
  couponStatus?: string
  couponLoading?: boolean
  onActiveCoupon?: () => void
}

export interface IUpsellingShelvesProps {
  products: Partial<AvailableProduct>[] & Partial<Product>[]
}

export const UpsellingShelves = ({
  products
}: IUpsellingShelvesProps): React.ReactElement => {
  const shelvesInfoRef = useRef([])
  const {
    isMobile,
    shelvesData,
    shelvesHandlers: { handleAdd, handleOnClick, handleRemove }
  } = useUpsellingShelves({ products })

  return (
    <>
      <Row
        isWrap
        overflow='auto'
      >
        {isValidArrayWithData(shelvesData) &&
          shelvesData.map((product, index) => {
            const {
              isPromotion,
              isPaymentsMethods,
              price,
              pricePromotion,
              ppum,
              offer,
              pricePaymentsMethods
            } = statusPromotionsV2(product)
            const promotion = {
              ...product?.priceDetail?.promotionalTag,
              backgroundColor: product?.priceDetail?.promotionalTag?.color,
              label: product?.priceDetail?.promotionalTag?.text,
              color: product?.priceDetail?.promotionalTag?.textColor
            }

            const urlName = formatProductUrl(product?.['slug'])
            const format =
              product?.measurementUnit?.toLowerCase() === 'kg'
                ? product?.['netContentLevelSmall']
                : product?.['netContent']

            const typePromotion = {
              '': 0,
              price: 1,
              percentage: 2,
              mxn: 3,
              mx$: 4,
              'm%n': 5
            }

            return (
              <React.Fragment key={product?.itemId}>
                <MemoShelf
                  {...{ ...product, format }}
                  altText={product.name}
                  catchShelfRef={(ref) => {
                    if (!ref) return

                    shelvesInfoRef.current = [
                      ...shelvesInfoRef.current,
                      { ref, product, position: index + 1 }
                    ]
                  }}
                  couponChannel={product?.coupon?.entry?.fields?.channel}
                  handleOnClick={(props) => {
                    handleOnClick(props, product?.priceDetail?.promotionId)
                  }}
                  img={product?.item?.images?.[0] || product?.images?.[0]}
                  inOffer={product?.sellers?.[0]?.inOffer}
                  isMobile={isMobile}
                  isMporcentageNPromo={
                    product?.priceDetail?.promotionType === 'm%n'
                  }
                  isMxNPromotion={product?.priceDetail?.promotionType === 'mxn'}
                  isPaymentsMethods={isPaymentsMethods}
                  isPromotion={isPromotion}
                  itemRequired={product?.priceDetail?.itemsRequiredForPromotion}
                  likeListButtons={false}
                  listPrice={product?.priceDetail?.listPrice}
                  membershipData={product?.priceDetail?.membership}
                  nextImage={Image}
                  offer={offer}
                  orientation='vertical'
                  ppum={`(${ppum})`}
                  ppumListPrice={product?.priceDetail?.ppumListPrice}
                  price={price}
                  priceDetail={product?.priceDetail}
                  pricePaymentsMethods={pricePaymentsMethods}
                  pricePromotion={pricePromotion}
                  promotion={promotion}
                  quantityButtonProps={{
                    ...product?.['quantityButtonProps'],
                    handleAdd: (props) => {
                      handleAdd(props, product?.priceDetail?.promotionId)
                    },
                    handleRemove: (props) => {
                      handleRemove(props, product?.priceDetail?.promotionId)
                    },
                    handleChange
                  }}
                  quantityButtonStyles={{
                    fontSize: 'md',
                    maxHeight: '30px',
                    maxWidth: '90px',
                    sizeCircle: 22
                  }}
                  router={router}
                  saving={product?.priceDetail?.promotionMessage}
                  showAddToFavoriteButton={true}
                  showAddToListButton={true}
                  title={product?.['item']?.name || product?.name}
                  typePromotion={
                    typePromotion[product?.priceDetail?.promotionType]
                  }
                  url={urlName}
                />
              </React.Fragment>
            )
          })}
      </Row>
    </>
  )
}
