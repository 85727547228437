import { useState, useEffect } from 'react'
import { extractProductsAndCount } from '../../helpers'
import {
  paginationCal,
  useSearchSuggestions,
  isContinuosFetching,
  useProductsSearch
} from '@smu-chile/pkg-unimarc-hooks'
import { REACT_QUERY_GENERAL } from '@smu-chile/pkg-unimarc-hooks/shared/constants'
import { AvailableProduct } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IIntelligenceSearch'
import { Product } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IProducts'

const initialLimit = 49

interface IUseUpsellingProductListViewProps {
  isSuggestionsLoading: boolean
  isSuggestionsError: boolean
  isProductsLoading: boolean
  isProductsError: boolean
  products: Partial<AvailableProduct>[] & Partial<Product>[]
  suggestions: string[]
}

/**
 * Custom hook to fetch and manage upselling product list view data.
 *
 * Combines search suggestions and product search logic with support for
 * pagination and continuous fetching of products.
 *
 * @returns {Object} Object containing loading and error states, suggestions, and products list.
 *
 * @example
 * const {
 *   isSuggestionsLoading,
 *   isSuggestionsError,
 *   isProductsLoading,
 *   isProductsError,
 *   products,
 *   suggestions
 * } = useUpsellingProductListView()
 */
export const useUpsellingProductListView =
  (): IUseUpsellingProductListViewProps => {
    const page = 1
    const { offset, end: limit } = paginationCal({ page, limit: 50 })
    const nextLimit = offset + initialLimit
    const [resources, setResources] = useState(Infinity)
    const [products, setProducts] = useState([])
    //TODO: we need to use setUseCountLimit in the next ticket for pagination
    const [countLimit, setCountLimit] = useState(nextLimit)
    const reactQueryBySearch = {
      ...REACT_QUERY_GENERAL
    }
    const {
      data: suggestionsData,
      isLoading: isSuggestionsLoading,
      isError: isSuggestionsError
    } = useSearchSuggestions({
      product: 'carne',
      site: 'unimarc'
    })
    const suggestions = suggestionsData?.suggestions
    const continuosFetching: boolean = isContinuosFetching({
      countLimit,
      resources,
      limit
    })

    // TODO: implement search term in "searching" and queryKey
    const {
      data: productsData,
      isLoading: isProductsLoading,
      isError: isProductsError
    } = useProductsSearch({
      from: offset.toString(),
      orderBy: '',
      searching: 'carne',
      to: '49',
      reactQuery: {
        ...reactQueryBySearch,
        queryKey: ['search', 'carne']
      }
    })

    // Update products per page, there logic to implement the fragment
    // request and show the products in two or more steps
    useEffect(() => {
      if (!productsData || isProductsLoading) return

      const { newProducts, totalCount } = extractProductsAndCount(productsData)

      // Solo actualiza el total si cambió
      if (totalCount !== resources) setResources(totalCount)

      setProducts((prev) => {
        const prevData = continuosFetching ? prev : []
        return [...prevData, ...newProducts]
      })
    }, [productsData])

    return {
      isSuggestionsLoading,
      isSuggestionsError,
      isProductsLoading,
      isProductsError,
      products,
      suggestions
    }
  }
