import { IOrder } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IOrder'
import { IHandleStatusText } from '../interfaces/IShelfList'
import { logicHandleStatus, requestRecived } from './logicHandleStatus'
import { IGetDetailOrderResponse } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IGetOrders'

export const handleStatusTextOrderDetail = (
  order?: IGetDetailOrderResponse
): IHandleStatusText => {
  if (!order) return requestRecived
  const { address, status } = order
  return logicHandleStatus(status?.toLowerCase() ?? '', address?.addressType)
}
