export const queryUnipayData = `query queryUnipayData($id: String!) {
  viewBlock(id: $id) {
    blockId
    itemsCollection(where: {sys: {id_exists: true}}, limit: 10) {
      items {
        ... on ImageBannerWeb {
          sys {
            id
          }
          label
          imageDesktop {
            url
          }
          imageMobile {
            url
          }
        }
        ... on ViewBlock {
          sys {
            id
          }
          blockId
          description
          itemsCollection(where: {sys: {id_exists: true}}, limit: 10) {
            items {
              ... on NavigationItem {
                label
                subtitulo
                navigateTo
                action
                url
                icon {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
}`
