import { Row, Column, Picture, Icon } from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers/mediaQueries'
import { useState } from 'react'

export interface ImageProps {
  altPicture: string
  customSize?: string
  draggable?: boolean
  isClickable?: boolean
  mobileCustomSize?: string
  notAvailable?: boolean
  sizeNotFoundDesktop?: number
  sizeNotFoundMobile?: number
  srcPicture: string
}

export const Image = ({
  altPicture,
  customSize,
  draggable,
  isClickable,
  mobileCustomSize = '65px',
  notAvailable,
  sizeNotFoundDesktop = 84.36,
  sizeNotFoundMobile = 65,
  srcPicture
}: ImageProps): React.ReactElement<ImageProps> => {
  const [isErrorImg, setIsErrorImg] = useState(false)
  const clickable = isClickable ? 'pointer' : 'auto'
  const allowClickable = isClickable ? true : false

  return (
    <>
      <BigScreen>
        <Column>
          <Row
            clickable={clickable}
            justifyContent='center'
          >
            {isErrorImg || !srcPicture ? (
              <Icon
                customSize={sizeNotFoundDesktop}
                name='ShelvesDefaultImage'
              />
            ) : (
              <Picture
                alt={altPicture}
                border='none'
                borderRadius='none'
                draggable={draggable}
                grayscale={notAvailable}
                hasURL={allowClickable}
                height={customSize ? customSize : '100%'}
                onError={() => {
                  setIsErrorImg(true)
                }}
                src={srcPicture}
                width={customSize ? customSize : '100%'}
              />
            )}
          </Row>
        </Column>
      </BigScreen>

      <SmallScreen>
        <Column>
          <Row justifyContent='center'>
            {isErrorImg || !srcPicture ? (
              <Icon
                customSize={sizeNotFoundMobile}
                name='ShelvesDefaultImage'
              />
            ) : (
              <Picture
                alt={altPicture}
                border='none'
                borderRadius='none'
                grayscale={notAvailable}
                height={customSize ? customSize : mobileCustomSize}
                onError={() => {
                  setIsErrorImg(true)
                }}
                src={srcPicture}
                width={customSize ? customSize : mobileCustomSize}
              />
            )}
          </Row>
        </Column>
      </SmallScreen>
    </>
  )
}
