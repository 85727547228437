import '@smu-chile/pkg-unimarc-components/assets/styles/globals.css'
import '@smu-chile/pkg-unimarc-components/assets/styles/tokens.css'
import {
  QueryClient,
  QueryClientProvider
} from '@smu-chile/pkg-unimarc-hooks/reactQuery'
import { ReactQueryDevtools } from '@smu-chile/pkg-unimarc-hooks/reactQuery/devtools'
import CurtainWrapper from 'components/CurtainWrapper'
import { Layout } from 'components/Layout'
import Script from 'next/script'
import { AppPropsWithLayout } from 'shared/interfaces/Layout'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import '../styles/globals.css'

const queryClient = new QueryClient()

function MyApp({
  Component,
  pageProps,
  isInvalidUrlForReCaptcha
}: AppPropsWithLayout): React.ReactElement {
  return (
    <>
      <Script
        src={`https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY}&libraries=places`}
        strategy='beforeInteractive'
      />
      <Script
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer', 'GTM-TSJPRBR');`
        }}
        strategy='afterInteractive'
      />
      {!isInvalidUrlForReCaptcha ? (
        <GoogleReCaptchaProvider
          language='es-419'
          reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_V3_KEY}
          scriptProps={{
            async: false,
            defer: true
          }}
          useEnterprise={true}
        >
          <QueryClientProvider client={queryClient}>
            <CurtainWrapper>
              <Layout>
                <Component {...pageProps} />
              </Layout>
            </CurtainWrapper>
            <ReactQueryDevtools />
          </QueryClientProvider>
        </GoogleReCaptchaProvider>
      ) : (
        <QueryClientProvider client={queryClient}>
          <CurtainWrapper>
            <Layout>
              <Component {...pageProps} />
            </Layout>
          </CurtainWrapper>
          <ReactQueryDevtools />
        </QueryClientProvider>
      )}
    </>
  )
}

MyApp.getInitialProps = ({ ctx }) => {
  const isInvalidUrlForReCaptcha = ctx?.req?.url?.includes('source=APP')
  return {
    isInvalidUrlForReCaptcha
  }
}

export default MyApp
