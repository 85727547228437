import {
  Column,
  Container,
  Row,
  Spacer
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  getGlobalStyle,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import { DAYS } from 'shared/constants'
import { DeliveryDate } from './DeliveryDate'
import { PaymentMethods } from './PaymentMethods'
import { Shipping } from './Shipping'
import { timeUTCFilter } from 'shared/helpers'
import { capitalize, formatDate, useMobile } from '@smu-chile/pkg-unimarc-hooks'
import { TextPostPicking } from './PaymentMethods/TextPostPicking'
import { PAYMENT_WEB_PAY_NAMES } from 'shared/helpers/paymentSystems'
import { IGetDetailOrderResponse } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IGetOrders'

enum AddressType {
  Residential = 'residential'
}

interface ShippingTypeProps {
  order?: IGetDetailOrderResponse
  paymentCardImage: Record<string, { url: string }>
}

export const ShippingType = ({
  order,
  paymentCardImage
}: ShippingTypeProps) => {
  const { isMobile } = useMobile()

  const paymentName = order?.paymentData?.paymentName ?? ''

  const { address, deliveryWindow } = order

  const {
    addressType,
    street,
    number,
    neighborhood,
    state,
    complement,
    friendlyName
  } = address
  const numberDepartament = complement ? `, ${complement},` : ','
  const icon = addressType === AddressType.Residential ? 'Motorcycle' : 'Shop'
  const tittle =
    addressType === AddressType.Residential
      ? 'Despacho a domicilio:'
      : 'Retiro en tienda:'
  const deliveryAddress =
    addressType === AddressType.Residential
      ? `${street} ${number}${numberDepartament} ${neighborhood}, ${capitalize(
          state
        )}.`
      : `${friendlyName} - ${street} ${number}${numberDepartament} ${neighborhood}, ${capitalize(
          state
        )}.`
  let deliveryDateText
  if (deliveryWindow) {
    const { startDateUtc, endDateUtc } = deliveryWindow
    const startDate = new Date(startDateUtc)
    const endDate = new Date(endDateUtc)
    deliveryDateText = `${DAYS[startDate.getDay()]} ${formatDate(
      startDateUtc
    ).replace(/-/gi, '/')}, ${timeUTCFilter(startDate)} - ${timeUTCFilter(
      endDate
    )} hrs.`
  }

  const paddingDesktopMessageWebPay = isMobile
    ? '15px 10px 5px 10px'
    : '18px 30px'

  return (
    <>
      <BigScreen>
        <Container
          backgroundColor={getGlobalStyle('--color-neutral-gray-light')}
          borderRadius='4px'
          isWrap
          tagName='section'
        >
          <Row
            borderRadius='4px'
            minHeight='100px'
            tagName='article'
          >
            <Column>
              <Shipping
                address={deliveryAddress}
                icon={icon}
                tittle={tittle}
              />
            </Column>
            <Column>
              <DeliveryDate
                deliveryDate={deliveryDateText ? deliveryDateText : ''}
              />
            </Column>
            <Column>
              <PaymentMethods
                order={order}
                paymentCardImage={paymentCardImage}
              />
            </Column>
          </Row>

          {paymentName === PAYMENT_WEB_PAY_NAMES[0] && (
            <Row
              isWrap
              margin={paddingDesktopMessageWebPay}
            >
              <Spacer.Horizontal
                backgroundColor={getGlobalStyle('--color-neutral-gray')}
                size={1}
              />
              <Spacer.Horizontal size={8} />
              <TextPostPicking />
            </Row>
          )}
        </Container>
      </BigScreen>
      <SmallScreen>
        <Container
          isWrap
          margin='0 12px'
        >
          <Row isWrap>
            <Column>
              <Shipping
                address={deliveryAddress}
                icon={icon}
                tittle={tittle}
              />
            </Column>
            <Spacer.Horizontal size={16} />
            <Column>
              <DeliveryDate
                deliveryDate={deliveryDateText ? deliveryDateText : ''}
              />
            </Column>
          </Row>
          <Spacer.Horizontal size={16} />
          <Row>
            <Column>
              <PaymentMethods
                order={order}
                paymentCardImage={paymentCardImage}
              />
            </Column>
          </Row>
        </Container>
      </SmallScreen>
    </>
  )
}
