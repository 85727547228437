import {
  Column,
  Container,
  Icon,
  Row,
  Spacer,
  Text
} from '@smu-chile/pkg-unimarc-components'
import { useMobile, useGetDetailOrder } from '@smu-chile/pkg-unimarc-hooks'
import {
  BigScreen,
  getGlobalStyle,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'

interface ReadjustmentProps {
  id: string
  measurementUnit: string
  price: number
  quantity: number
}

export const Readjustment = ({
  id,
  measurementUnit,
  price,
  quantity
}: ReadjustmentProps) => {
  const { data } = useGetDetailOrder()
  const { isMobile } = useMobile()

  const getSellingPriceTotal = data?.items
    ?.filter((item) => {
      return item.id === id
    })
    .map((order) => {
      return order?.total ?? 0
    })

  const pickingPrice = Number(quantity * price)

  const sellingPriceTotal = Number(getSellingPriceTotal)

  const finalAccount = Math.round((pickingPrice - sellingPriceTotal) / 100)
  const replace = finalAccount > 0 ? /[^a-z0-9]-/i : /[^a-z0-9]+/i
  const formattedAmount = new Intl.NumberFormat('es-CL')
    .format(finalAccount)
    .replace(replace, '')

  const icon = finalAccount > 0 ? 'DollarCircleUp' : 'DollarCircleDown'
  const word = finalAccount > 0 ? 'aumentó' : 'disminuyó'
  const sing = finalAccount > 0 ? '+' : '-'
  const equivalenceUnit =
    measurementUnit === 'kg' ? 'El peso enviado' : 'La cantidad enviada'
  const maxWidth = equivalenceUnit === 'El peso enviado' && 'maxWidth= 26px'
  const widthByEquivalence =
    equivalenceUnit === 'El peso enviado' ? '65%' : '77%'

  return (
    <Container
      isWrap
      width='100%'
    >
      <Row>
        <Column
          alignItems='start'
          margin='0 -3px'
          width={34}
        >
          <Row
            backgroundColor='rgba(228, 247, 252, 1)'
            borderRadius={getGlobalStyle('--border-radius-full')}
            margin='4px'
            maxWidth='26px'
            minHeight='26px'
            padding='2px'
          >
            <Column
              alignItems='center'
              justifyContent='center'
              margin='1px 0 0'
              width={34}
            >
              <Icon
                name={icon}
                sizes='2xs'
              />
            </Column>
          </Row>
        </Column>

        <Column
          margin='10px 0 0'
          maxWidth={isMobile ? null : maxWidth}
          width={isMobile ? '100%' : widthByEquivalence}
        >
          <Row width='103%'>
            <Text
              color='secondary-blue'
              fontSize='sm'
            >
              {equivalenceUnit}
            </Text>
            <Spacer.Vertical size={4} />
            <Text
              color='secondary-blue'
              fontSize='sm'
              fontWeight='medium'
            >
              {word}
            </Text>
            <Spacer.Vertical size={4} />
            <Text
              color='secondary-blue'
              fontSize='sm'
            >
              el cobro en
            </Text>

            <Spacer.Vertical size={4} />

            <BigScreen>
              <Column
                margin='-2px 1px'
                width={1}
              >
                <Text
                  color='secondary-blue'
                  fontSize='md'
                  fontWeight='medium'
                >
                  {`${sing}$${formattedAmount}`}
                </Text>
              </Column>
            </BigScreen>

            <SmallScreen>
              <Text
                color='secondary-blue'
                fontSize='sm'
                fontWeight='medium'
              >
                {`${sing}$${formattedAmount}`}
              </Text>
            </SmallScreen>
          </Row>
        </Column>
      </Row>
    </Container>
  )
}
