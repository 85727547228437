import { ReactElement, useEffect } from 'react'
import {
  DesktopModalWrapper,
  MobileModalWrapper
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  getBemId,
  getGlobalStyle,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import styles from './UpsellingModalWrapper.module.css'
import { sleep } from '@smu-chile/pkg-unimarc-hooks'
import { UpsellingProductListViewWrapper } from 'components/UpsellingProductListViewWrapper'

interface IUpsellingModalWrapperProps {
  isOpen: boolean
  width?: string
  handleToggleModal: () => void
}

export const UpsellingModalWrapper = ({
  isOpen,
  width = '440px',
  handleToggleModal
}: IUpsellingModalWrapperProps): ReactElement => {
  const onClose = async () => {
    const sideModal = document.getElementById('upsellingModal__container')
    if (sideModal) sideModal.classList.remove(styles['show'])
    await sleep(500)
    handleToggleModal()
  }

  useEffect(() => {
    const animation = async () => {
      const sideModal = document.getElementById('upsellingModal__container')
      if (sideModal) {
        await sleep(100)
        sideModal.classList.add(styles['show'])
      }
    }
    animation()
  }, [])

  return (
    <>
      <BigScreen>
        <DesktopModalWrapper
          blockId={getBemId('upsellingModal', 'modal')}
          bodyChildrenProps={{
            minHeight: '100px',
            maxHeight: 'calc(100vh - 59px)',
            padding: '0',
            customClassName: styles['modal__container']
          }}
          borderEdge='bottom'
          footerChildrenProps={{
            default: false
          }}
          hasfooter={false}
          headerCloseIcon={{
            default: true,
            name: 'CloseThin',
            sizes: '2xs'
          }}
          headerContainerProps={{
            style: {
              minHeight: '68px',
              padding: '0 20px'
            }
          }}
          headerTitle='Agregar productos'
          headerTitleProps={{
            style: {
              fontSize: getGlobalStyle('--font-size-base'),
              fontWeight: '600'
            }
          }}
          modalConfigsProps={{
            isOpen: isOpen,
            containerId: getBemId('upsellingModal', 'container'),
            customContainerClassName: styles['modal__overlay'],
            minHeight: 'calc(100vh - 16px)',
            toggle: onClose,
            toggleOutside: onClose,
            isWindowBlocked: true,
            maxWidth: width
          }}
        >
          <UpsellingProductListViewWrapper />
        </DesktopModalWrapper>
      </BigScreen>

      <SmallScreen>
        <MobileModalWrapper
          blockId={getBemId('upsellingModal', 'modal')}
          body={<UpsellingProductListViewWrapper />}
          bodyContainerProps={{
            padding: '0',
            overflow: 'visible'
          }}
          buttonConfigsProps={{
            fontWeight: getGlobalStyle('--font-weight-medium'),
            background: getGlobalStyle('--color-primary-red2'),
            label: 'Pagar',
            isLoading: false
          }}
          dragProps={{
            draggButton: false
          }}
          hasFooter={false}
          headerCloseIconProps={{
            customSize: 20
          }}
          headerProps={{
            padding: '3px 16px'
          }}
          headerTitle='Agregar productos'
          iconColor={getGlobalStyle('--color-base-black')}
          iconSize={20}
          isEnableButton
          isOpen={isOpen}
          modalConfigsProps={{
            openAnimation: true,
            fullScreenSticky: true,
            hiddenX: true,
            isAutoHeight: true,
            isOpen: true,
            isWindowBlocked: true,
            marginFullScreen: '1vh 0 0',
            stickyBottom: true,
            toggle: handleToggleModal,
            toggleOutside: handleToggleModal
          }}
          onClose={handleToggleModal}
          styleProps={{
            padding: '0',
            height: '100%',
            borderRadius: `${getGlobalStyle(
              '--border-radius-md'
            )} ${getGlobalStyle('--border-radius-md')} 0 0`
          }}
        />
      </SmallScreen>
    </>
  )
}
