import React from 'react'
import {
  Column,
  Container,
  Icon,
  Row,
  Spacer,
  Text
} from '@smu-chile/pkg-unimarc-components'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { IGetDetailOrderResponse } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IGetOrders'
import { BankStatePayment } from './BankStatePayment'
import { CardPayment } from './CardPayment'
import { GiftCardPayment } from './GiftCardPayment'
import { KlapPayment } from './KlapPayment'
import { WebPayPayment } from './WebPayPayment'
import {
  BANK_STATE_PAYMENTS,
  KLAP_PAYMENTS,
  WEBPAY_PAYMENTS,
  GIFT_CARD_PAYMENTS,
  UNIPAY_PAYMENTS,
  OTHER_PAYMENTS
} from '@smu-chile/pkg-unimarc-hooks/hooks/usePaymentMethodsImages/constants'
import { findMasterPaymentItem } from '@smu-chile/pkg-unimarc-hooks'

interface OrderDetailProps {
  order?: IGetDetailOrderResponse
  paymentCardImage: Record<
    string,
    {
      url: string
    }
  >
}

export const PaymentMethods = ({
  order,
  paymentCardImage
}: OrderDetailProps): React.ReactElement | null => {
  const { isMobile } = useMobile()
  const paddingMobile = isMobile ? '0' : '18px 15px'

  const paymentData = order?.paymentData
  if (!paymentData) return null

  const {
    giftCardCaption,
    cardNumber,
    cardType,
    paymentId,
    paymentName,
    quotas,
    quotaValue,
    rate,
    totalCreditCost
  } = paymentData

  const item = findMasterPaymentItem(paymentId, paymentName)
  const cardTypeKey = item.cardTypeKey
  const finalUrl = paymentCardImage[cardTypeKey]?.url ?? ''
  const renderPaymentComponent = () => {
    if (BANK_STATE_PAYMENTS.includes(item)) {
      return (
        <BankStatePayment
          bankStateImage={finalUrl}
          cardNumber={cardNumber || ''}
        />
      )
    }
    if (KLAP_PAYMENTS.includes(item)) {
      return (
        <KlapPayment
          finalUrl={finalUrl}
          paymentName={paymentName}
        />
      )
    }
    if (WEBPAY_PAYMENTS.includes(item)) {
      return (
        <WebPayPayment
          paymentImage={finalUrl}
          paymentName={paymentName}
        />
      )
    }
    if (GIFT_CARD_PAYMENTS.includes(item)) {
      return (
        <GiftCardPayment
          finalUrl={finalUrl}
          number={giftCardCaption || 'Giftcard'}
        />
      )
    }
    if (UNIPAY_PAYMENTS.includes(item)) {
      return (
        <CardPayment
          cardNumber={cardNumber || ''}
          cardType={paymentName}
          finalUrl={finalUrl}
          isMobile={isMobile}
          quotaValue={quotaValue}
          quotas={quotas}
          rate={rate}
          totalCreditCost={totalCreditCost}
        />
      )
    }
    if (OTHER_PAYMENTS.includes(item)) {
      return (
        <CardPayment
          cardNumber={cardNumber || ''}
          cardType={cardType?.toLowerCase() || paymentName}
          finalUrl={finalUrl}
          isMobile={isMobile}
          quotaValue={quotaValue}
          quotas={quotas}
          rate={rate}
          totalCreditCost={totalCreditCost}
        />
      )
    }
    return (
      <CardPayment
        cardNumber={cardNumber || ''}
        cardType={cardType?.toLowerCase() || paymentName}
        finalUrl={finalUrl}
        isMobile={isMobile}
        quotaValue={quotaValue}
        quotas={quotas}
        rate={rate}
        totalCreditCost={totalCreditCost}
      />
    )
  }

  return (
    <Container
      isWrap
      padding={paddingMobile}
    >
      <Row alignItems={isMobile ? 'center' : 'start'}>
        <Icon
          color={getGlobalStyle('--color-base-black')}
          customSize={24}
          name='Card'
        />
        <Spacer.Vertical size={8} />
        <Text
          fontSize='md'
          fontWeight='medium'
        >
          Medio de pago:
        </Text>
      </Row>
      <Column
        alignItems='center'
        padding='0 0 0 32px'
      >
        <Spacer.Horizontal size={4} />
        {renderPaymentComponent()}
      </Column>
    </Container>
  )
}
