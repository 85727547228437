import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import {
  Button,
  Column,
  Container,
  Icon,
  Row,
  Spacer,
  Text
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers/mediaQueries'
import {
  eventClick,
  getParamsInURL,
  IMdpConfigResponse,
  useMobile,
  trigger
} from '@smu-chile/pkg-unimarc-hooks'
import { ImageCard } from './components/ImageCard'
import { ListProducts, statusPostPicking, statusPrePicking } from './components'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import { getTotalQuantityProducts } from 'shared/helpers'
import { GenericImage } from './components/GenericImage'
import { IGetDetailOrderResponse } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IGetOrders'
import styles from '../../OrderDetail.module.css'

interface ProductsProps {
  mdpConfigResponse: IMdpConfigResponse
  order: IGetDetailOrderResponse
  text?: string
  ticketNumber?: string
}

export const Products = ({
  mdpConfigResponse,
  order,
  text,
  ticketNumber
}: ProductsProps): React.ReactElement => {
  const { isMobile } = useMobile()
  const router = useRouter()
  let totalQuantityProducts = 0
  const [displayButtonUpselling, setDisplayButtonUpselling] = useState(false)
  const paramsApp = getParamsInURL('source')
  const seller = getParamsInURL('seller')
  const addParamsInUrl = !paramsApp ? '' : '&source=APP'
  const quantityImages = isMobile ? 7 : 8
  const showImagesNumber =
    order.items?.length === quantityImages ? quantityImages : quantityImages - 1

  const handleTriggerModalUpsellingEvent = () => {
    trigger({
      eventType: 'upsellingModal',
      data: { isOpen: true }
    })
  }

  if (statusPrePicking.includes(text)) {
    const countItems = getTotalQuantityProducts(order.items)
    totalQuantityProducts = countItems
  }
  if (statusPostPicking.includes(text)) {
    const countItemsSent = getTotalQuantityProducts(order.itemsSent)
    const countItemsAdded = getTotalQuantityProducts(order.itemsAdded)
    totalQuantityProducts = countItemsSent + countItemsAdded
    if (order?.itemsSent?.length === 0 && order?.itemsAdded?.length === 0) {
      totalQuantityProducts = getTotalQuantityProducts(order.items)
    }
  }

  useEffect(() => {
    const flag =
      mdpConfigResponse?.data?.mdpConfig?.mdp.includes(
        order?.paymentData?.paymentId
      ) &&
      mdpConfigResponse?.data?.mdpConfig?.status?.includes(order?.status) &&
      mdpConfigResponse?.data?.mdpConfig?.operator?.includes(
        order?.logisticOperator
      )
    setDisplayButtonUpselling(flag)
  }, [mdpConfigResponse, order])

  return (
    <>
      <BigScreen>
        <Container
          customWidth='100'
          isWrap
          tagName='article'
        >
          <ListProducts
            displayButtonUpselling={displayButtonUpselling}
            order={order}
            text={text}
          />
        </Container>
      </BigScreen>
      <SmallScreen>
        <>
          {/* more products */}
          <Container
            clickable='pointer'
            isWrap
            onClick={() => {
              eventClick({
                event: 'interacciones-mis-compras',
                eventCategory: 'ver-todos-los-productos'
              })
              return router.push(
                `/ProductsDetails?og=${ticketNumber}&seller=${seller}${addParamsInUrl}`
              )
            }}
            padding='16px 0 16px 12px'
            tagName='article'
          >
            <Row
              margin='-3px 0px'
              padding='0 12px 0 0'
            >
              <Column>
                <Row>
                  <Text
                    customColor={getGlobalStyle('--color-text-black')}
                    fontSize='md'
                    fontWeight='medium'
                  >
                    Productos
                  </Text>
                  <Spacer.Vertical size={4} />
                  <Text
                    customColor={getGlobalStyle('--color-text-gray-light')}
                    fontSize='md'
                  >
                    ({totalQuantityProducts})
                  </Text>
                </Row>
              </Column>
              <Column customWidth='06'>
                <Spacer.Horizontal size={2} />
                <Icon
                  color={getGlobalStyle('--color-neutral-gray-dark')}
                  customSize={20}
                  name='ArrowRightNavigate2'
                />
              </Column>
            </Row>
            <Row
              className={`${styles.container} ${styles.witdSpacer} ${styles.gradient} `}
            >
              {order.items
                .filter((_item, i) => {
                  return i < showImagesNumber
                })
                .map((item) => {
                  const { image } = item

                  return (
                    <React.Fragment key={`key-${item.id}`}>
                      <ImageCard
                        imageSize='50px'
                        imageUrl={image}
                      />
                    </React.Fragment>
                  )
                })}
              {totalQuantityProducts > showImagesNumber &&
                order.items.length > quantityImages && (
                  <GenericImage quantity={totalQuantityProducts} />
                )}
            </Row>
          </Container>

          {/* upselling button */}
          <Container
            id={getBemId('products', 'button-upselling')}
            padding='0 12px 16px 12px'
          >
            {displayButtonUpselling && (
              <Row
                alignItems='center'
                justifyContent='center'
              >
                <Column
                  alignItems='center'
                  clickable='pointer'
                  height={isMobile ? '32px' : '36px'}
                  justifyContent='center'
                  minHeight={isMobile ? '32px' : '36px'}
                >
                  <Button
                    background='transparent'
                    border={`1px solid ${getGlobalStyle('--color-base-black')}`}
                    borderRadius={getGlobalStyle('--border-radius-5xl')}
                    color={getGlobalStyle('--color-base-black')}
                    fontSize='12px'
                    fontWeight='500'
                    height='32px'
                    label='Agregar más productos a la compra'
                    onClick={handleTriggerModalUpsellingEvent}
                    prevIcon='Plus'
                    prevIconClickable='pointer'
                    prevIconColor={getGlobalStyle('--color-base-black')}
                    prevIconSize={14}
                    width='100%'
                  />
                </Column>
              </Row>
            )}
          </Container>
        </>
      </SmallScreen>
    </>
  )
}
