import { useState } from 'react'
import {
  datalayerSimpleEvent,
  eventClick,
  sleep,
  useContentful,
  useGetDetailOrder,
  useList,
  useSession,
  useSessionState
} from '@smu-chile/pkg-unimarc-hooks'
import { postNameToList } from '@smu-chile/pkg-unimarc-hooks/services'
import { v4 as uuidv4 } from 'uuid'
import { calculateProductsAmount, getSessionStorageItem } from 'shared/helpers'
import { IProductInList } from '@smu-chile/pkg-unimarc-components/shared/interfaces/ILists'
import { EMPTY_ORANGE_IMG } from 'shared/constants'

/**
 * Manage properties like open modal, identify if is a new list, show tooltip, save list's name
 * call the POST API for creating new list or add product into a list
 * @returns createListButton
    isLoggedIn
    listData
    notificationAddToList
    openModalCreateList
    selectedListName
    handleAddProductsToList
    handleCreateNewList
    handleGoToMyLists
    handleOnClosed
    handleOpenMyListsModal
    setCreateListButton
    setNotificationAddToList
    setOpenModalCreateList
 */
export const useAddProductToList = () => {
  const { isLoggedIn } = useSession()
  const { data } = useGetDetailOrder()

  const orderProducts = data?.items

  const { data: list, refetch: refetchUseList } = useList({
    order: 1,
    page: 1,
    perPage: 15
  })

  const emptyOrangeImg = useContentful({
    id_contentful: 'contentful-builtin-asset-content-type-orange-img',
    options: {
      'sys.id': EMPTY_ORANGE_IMG[0]
    },
    type: 'assets'
  })

  const emptyOrangeImage =
    emptyOrangeImg?.data?.['items'][0]?.fields?.file?.url || ''

  const [openModalCreateList, setOpenModalCreateList] = useState(false)
  const [createListButton, setCreateListButton] = useState(false)
  const [notificationAddToList, setNotificationAddToList] = useState(false)
  const [selectedListName, setSelectedListName] = useSessionState(
    'selectedListName',
    ''
  )
  const [selected, setSelected] = useSessionState('selected', {
    id: '',
    name: '',
    products: []
  })

  const handleOpenMyListsModal = (openModal: boolean) => {
    if (openModal === true) {
      datalayerSimpleEvent({
        event: 'interacciones_mis_listas',
        eventAction: 'view',
        eventCategory: 'inicio_mis_listas',
        eventLabel: 'modal_nueva_lista'
      })
    }
    setOpenModalCreateList(openModal)
  }

  const handleAddProductsToList = async (selectedList: {
    id: string
    name: string
    products: IProductInList[]
  }) => {
    if (selectedList?.name !== '') {
      const orderProductsMap = new Map<string, IProductInList>()

      orderProducts?.forEach((item) => {
        const id = item?.productId
        if (id) {
          if (orderProductsMap.has(id)) {
            const existing = orderProductsMap.get(id)!
            existing.quantity = (existing.quantity || 0) + (item?.quantity || 0)
          } else {
            orderProductsMap.set(id, {
              brandName: item?.brandName,
              id,
              image: item?.image,
              measurenmentUnit: item?.measurementUnit,
              name: item?.name,
              quantity: item?.quantity,
              unitMultiplier: item?.unitMultiplier
            })
          }
        }
      })

      const finalProducts: IProductInList[] = []
      selectedList?.products?.forEach((oldElement) => {
        const id = oldElement?.id
        if (id) {
          if (orderProductsMap.has(id)) {
            finalProducts.push({
              ...oldElement,
              quantity:
                (oldElement?.quantity || 0) +
                (orderProductsMap.get(id)?.quantity || 0)
            })
            orderProductsMap.delete(id)
          } else {
            finalProducts.push(oldElement)
          }
        }
      })

      orderProductsMap.forEach((product) => {
        finalProducts.push(product)
      })

      setSelectedListName(selectedList?.name)
      await postNameToList({
        name: selectedList?.name,
        id: selectedList?.id,
        products: finalProducts
      })
      await refetchUseList()
      sleep(500)
      setSelected({
        name: selectedList?.name,
        id: selectedList?.id,
        products: finalProducts
      })
      setNotificationAddToList(true)
      setOpenModalCreateList(false)
    }
  }

  const handleCreateNewList = async (value: string) => {
    eventClick({
      event: 'interacciones_mis_listas',
      eventAction: 'clic',
      eventCategory: 'modal_nueva_lista',
      eventLabel: 'crear_nueva_lista'
    })
    const listId = uuidv4()
    const productsAdded = []
    orderProducts?.forEach((item) => {
      const brandName = item?.brandName
      const image = item.image
      const measurenmentUnit = item.measurementUnit
      const name = item.name
      const quantity = item.quantity
      const unitMultiplier = item.unitMultiplier
      const id = item.productId
      productsAdded.push({
        brandName,
        id,
        image,
        measurenmentUnit,
        name,
        quantity,
        unitMultiplier
      })
    })
    setSelectedListName(value || selected?.name)
    await postNameToList({ name: value, id: listId, products: productsAdded })
    await refetchUseList()
    setTimeout(() => {
      setSelected({ name: value, id: listId, products: productsAdded })
      setNotificationAddToList(true)
      setOpenModalCreateList(false)
    }, 500)
  }

  const handleGoToMyLists = () => {
    const selectedList = getSessionStorageItem('selected')
      ? JSON.parse(getSessionStorageItem('selected') as string)
      : []

    const quantityOfProducts = calculateProductsAmount(selectedList)

    const listToSend = {
      idsProductsList: [],
      listIcon: 'MyLists',
      listName: selectedListName,
      listProducts: selectedList?.products,
      listTotalProducts: quantityOfProducts,
      selectedList: selectedList?.id
    }

    window.sessionStorage.setItem('selectedList', JSON.stringify(listToSend))
    window.sessionStorage.setItem(
      'listCart',
      JSON.stringify(listToSend.listProducts)
    )
    window.location.href = '/ListDetails'
  }

  const handleOnClosed = () => {
    setNotificationAddToList(false)
  }

  return {
    createListButton,
    emptyOrangeImage,
    isLoggedIn,
    listData: list?.data,
    notificationAddToList,
    openModalCreateList,
    selectedListName,
    handleAddProductsToList,
    handleCreateNewList,
    handleGoToMyLists,
    handleOnClosed,
    handleOpenMyListsModal,
    setCreateListButton,
    setNotificationAddToList,
    setOpenModalCreateList
  }
}
