import {
  Row,
  Column,
  Vertical,
  Text,
  Horizontal,
  Spacer
} from '@smu-chile/pkg-unimarc-components'
import { Image } from './Image'
import { conditionalDecimal, Name } from './Name'
import { Price } from './Price'
import { Readjustment } from './Readjustment'
import {
  BigScreen,
  getGlobalStyle,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import { IItemOrder } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IGetOrders'

interface ProductDetailsProps {
  brandName: string
  item: IItemOrder
  latest?: boolean
  price: number
}

export const ProductDetails = ({
  item,
  latest,
  price
}: ProductDetailsProps): React.ReactElement => {
  const { id, image, measurementUnit, name, quantity, unitMultiplier } = item
  const weight = (item as { weight?: number }).weight ?? null

  const existWeight = weight ? true : false
  const unitFinal: string | number = conditionalDecimal(
    existWeight,
    quantity,
    unitMultiplier,
    weight
  )
  const customHeight = existWeight ? '129px' : '104px'
  const marginName = existWeight ? '0 0 -47px' : '0'

  return (
    <>
      <BigScreen>
        <Row
          alignItems='center'
          customHeight={customHeight}
          justifyContent='center'
        >
          <Column customWidth='16'>
            <Image
              altPicture='picture'
              customSize='80px'
              srcPicture={image}
            />
            <Vertical size={16} />
          </Column>
          <Spacer.Vertical customSize={33} />
          <Column
            alignItems='center'
            justifyContent='center'
            margin={marginName}
          >
            <Name
              measurementUnit={measurementUnit}
              name={name}
              quantity={quantity}
              unitMultiplier={unitMultiplier}
              weight={weight}
            />
            {existWeight === true && (
              <>
                <Horizontal size={8} />
                <Readjustment
                  id={id}
                  measurementUnit={measurementUnit}
                  price={price}
                  quantity={quantity}
                />
                <Horizontal size={8} />
              </>
            )}
          </Column>

          <Column
            alignItems='end'
            customWidth='20'
          >
            <Price totalPrice={price} />
          </Column>
        </Row>
      </BigScreen>

      <SmallScreen>
        <>
          <Row
            alignItems='center'
            justifyContent='center'
          >
            <Column
              customWidth='auto'
              width='auto'
            >
              <Image
                altPicture='picture'
                customSize='50px'
                srcPicture={image}
              />
            </Column>
            <Vertical size={8} />
            <Column isWrap>
              <Name
                measurementUnit={measurementUnit}
                name={name}
                quantity={quantity}
                unitMultiplier={unitMultiplier}
              />
              <Horizontal size={8} />
              <Row alignItems='center'>
                <Column>
                  <Text
                    customColor={getGlobalStyle('--color-neutral-gray-dark')}
                    fontSize='sm'
                  >
                    Cantidad: {weight ? weight : unitFinal} {measurementUnit}
                  </Text>
                </Column>
                <Column alignItems='end'>
                  <Price totalPrice={price} />
                </Column>
              </Row>
            </Column>
            <Vertical size={2} />
          </Row>
          <Row alignItems='start'>
            <Column>
              {existWeight === true && (
                <Readjustment
                  id={id}
                  measurementUnit={measurementUnit}
                  price={price}
                  quantity={quantity}
                />
              )}
            </Column>
          </Row>
          {!latest && <Horizontal size={24} />}
        </>
      </SmallScreen>
    </>
  )
}
