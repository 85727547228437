import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import {
  ButtonStatus,
  Column,
  Container,
  ModalBottomSheet,
  Picture,
  Row,
  Text
} from '@smu-chile/pkg-unimarc-components'
import {
  Entry,
  eventClick,
  getOrderId,
  IEntriesProps,
  useContentful,
  useGetDetailOrder,
  useMobile
} from '@smu-chile/pkg-unimarc-hooks'
import { putCancelPurchase } from '@smu-chile/pkg-unimarc-hooks/services/putCancelPurchase'
import { statusCancelPurshase } from 'components'
import { handleStatusTextOrderDetail } from 'shared/helpers'
import { IDS_CONTENT_FUL_BY_STORE } from 'shared/constants'
import { getTitleStore } from 'shared/helpers/getTitleStore'
import { IGetDetailOrderResponse } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IGetOrders'

interface ModalConfirmateProps {
  imageModalConfirmCancelPurchase: string
  openModalConfirmate: boolean
  selectOption: Entry
  selectOptionOther: string
  setOpenModalConfirmate: React.Dispatch<React.SetStateAction<boolean>>
  setOpenModalError: React.Dispatch<React.SetStateAction<boolean>>
  setOpenPage: React.Dispatch<React.SetStateAction<boolean>>
}
export const ModalConfirmate = ({
  imageModalConfirmCancelPurchase,
  openModalConfirmate,
  selectOption,
  selectOptionOther,
  setOpenModalConfirmate,
  setOpenModalError,
  setOpenPage
}: ModalConfirmateProps): React.ReactElement => {
  const router = useRouter()
  const { isMobile } = useMobile()
  const { data: dataOrder } = useGetDetailOrder()
  const { text } = handleStatusTextOrderDetail(dataOrder)
  const [loadingButton, setLoadingButton] = useState<ButtonStatus>('initial')
  const [dataContent, setDataContent] = useState<IEntriesProps>({})
  const courierNameNumber = dataOrder?.deliveryIds?.courierName.substring(0, 4)
  const ticketNumber = getOrderId()

  const { data: CFData, isLoading: isCFLoading } = useContentful({
    id_contentful: `viewBlock`,
    options: {
      'sys.contentType.sys.id[in]': 'viewBlock',
      'sys.id[in]': IDS_CONTENT_FUL_BY_STORE[0]
    },
    reactQuery: {
      enabled: Boolean(courierNameNumber),
      retry: 3,
      staleTime: 1000 * 60 * 5, // for five minutes the information is not outdated
      refetchOnWindowFocus: false
    }
  })

  const handleButtonClicked = (option: string) => {
    eventClick({
      event: 'interacciones_mis_compras',
      eventAction: 'clic',
      eventCategory: 'cancelar-compra-confirmar',
      estado_compra: text,
      event_label:
        (option == 'cancelar' && 'cancelar compra') ||
        (option == 'volver' && 'volver a mi compra'),
      id_compra: dataOrder?.sequence
    })
  }

  const handleCancelPurshase = async (dataOrder: IGetDetailOrderResponse) => {
    setLoadingButton('loading')
    const { text } = handleStatusTextOrderDetail(dataOrder)
    const enabledCancelPurshase = statusCancelPurshase.includes(text)
      ? false
      : true

    const option = {
      action: 'problema_con_productos_clone',
      otherReason: selectOptionOther,
      reason: selectOption?.fields?.value ?? '',
      store: getTitleStore(dataContent, courierNameNumber)
    }
    const bodyPutCancelPurshase = {
      application: 'web',
      courier: dataOrder?.deliveryIds?.courierName,
      name: dataOrder?.clientProfileData?.name,
      options: { ...option },
      order_id: ticketNumber as string,
      phone: dataOrder?.clientProfileData.phone,
      sequence: dataOrder?.sequence
    }

    const responsePut = await putCancelPurchase(bodyPutCancelPurshase)
    if (enabledCancelPurshase || responsePut.status === 410) {
      setOpenModalConfirmate(false)
      setOpenModalError(true)
    }

    if (responsePut.status === 201) {
      setOpenPage(true)
    }
  }

  const handleCancel = () => {
    handleButtonClicked('cancelar')
    handleCancelPurshase(dataOrder)
  }

  const handleBack = () => {
    handleButtonClicked('volver')
    setOpenModalConfirmate(false)
    router.back()
  }

  useEffect(() => {
    if (!isCFLoading && CFData) {
      setDataContent(CFData)
    }
  }, [isCFLoading, CFData])

  return (
    <ModalBottomSheet
      isMobile={isMobile}
      isOpen={openModalConfirmate}
      onClose={() => {
        return setOpenModalConfirmate(false)
      }}
      primaryBtnLabel='Cancelar compra'
      primaryCTA={handleCancel}
      secondaryBtnLabel='Volver atrás'
      secondaryCTA={handleBack}
      statusBtn={loadingButton}
    >
      <>
        <Column
          alignItems='center'
          margin='36px 0 0 0'
          width='250px'
        >
          <Picture
            alt='galleta'
            height='auto'
            src={imageModalConfirmCancelPurchase}
            width='198px'
          />
          <Container
            justifyContent='center'
            margin='16px 0'
          >
            <Text
              fontSize='xl'
              fontWeight='medium'
              textAlign='center'
            >
              Piénsalo bien...
            </Text>
          </Container>
        </Column>
        <Row maxWidth='277px'>
          <Text
            fontWeight='regular'
            isFlex
            textAlign='center'
          >
            Si cancelas tu compra no recibirás tus productos.
          </Text>
        </Row>
      </>
    </ModalBottomSheet>
  )
}
