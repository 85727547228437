import { useContentful, UseQueryResult } from '@smu-chile/pkg-unimarc-hooks'
import { queryUnipayData } from './queries'
import { UNIPAY_CONTENT, UNIPAY_PARENT_CONTENT } from './constants'

interface IContentfulResponse {
  data: {
    viewBlock: {
      blockId: string
      itemsCollection: {
        items: {
          sys: {
            id: string
          }
          label?: string
          imageDesktop?: {
            url: string
          }
          imageMobile?: {
            url: string
          }
          description?: string
          itemsCollection: {
            items: {
              subtitulo: string
              navigateTo: string
              action: string
              label: string
              url: string
              icon: {
                url: string
              }
            }[]
          }
        }[]
      }
    }
  }
}

export interface IuseMyUnipayProps {
  type: 'WITH_CARDS' | 'CREDIT_APROVAL' | 'WITHOUT_CREDIT_APROVAL'
}

export const useMyUnipay = ({ type }: IuseMyUnipayProps) => {
  const contentfulResponse = useContentful({
    id_contentful: 'unipay-content',
    graphQL: {
      query: queryUnipayData,
      variables: {
        id: UNIPAY_PARENT_CONTENT[type]
      }
    }
  }) as UseQueryResult<IContentfulResponse>

  const items =
    contentfulResponse?.data?.data?.viewBlock?.itemsCollection?.items || []
  const summaryIcon = items.find((item) => {
    return item?.sys?.id === UNIPAY_CONTENT.CARD_SUMMARY_ICON
  })
  const shortcuts = items.find((item) => {
    return item?.sys?.id === UNIPAY_CONTENT.SHORTCUTS_OPTIONS
  })
  const questions = items.find((item) => {
    return item?.sys?.id === UNIPAY_CONTENT.QUESTIONS_OPTIONS
  })

  return { ...contentfulResponse, summaryIcon, shortcuts, questions }
}
