import { Column } from '@smu-chile/pkg-unimarc-components'
import { ProductDetails } from './ProductDetails'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { IItemOrder } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IGetOrders'

interface ShelfProps {
  brandName: string
  item: IItemOrder
  latest?: boolean
  price: number
}

export const Shelf = ({
  brandName,
  item,
  latest,
  price
}: ShelfProps): React.ReactElement => {
  return (
    <Column
      backgroundColor={getGlobalStyle('--color-base-white')}
      position='relative'
      tagName='article'
    >
      {/* Product Details */}
      <ProductDetails
        brandName={brandName}
        item={item}
        latest={latest}
        price={price}
      />
    </Column>
  )
}
