import {
  BackboneShelves,
  Column,
  Container,
  Row,
  Spacer
} from '@smu-chile/pkg-unimarc-components'
import { getBemId } from '@smu-chile/pkg-unimarc-components/helpers'
import { CategoriesBarBackbone } from '@smu-chile/pkg-unimarc-components/stories/organisms/CategoriesBar/CategoryBackBone'
import { UpsellingShelves } from './components/UpsellingShelves'
import { UpsellingSuggestionPills } from './components/UpsellingSuggestionPills'
import { useUpsellingProductListView } from './hooks/useUpsellingProductListView'

export const UpsellingProductListViewWrapper = (): React.ReactElement => {
  const {
    products,
    suggestions,
    isProductsError,
    isProductsLoading,
    isSuggestionsError,
    isSuggestionsLoading
  } = useUpsellingProductListView()

  return (
    <>
      <Container isWrap>
        {/* suggestions pills */}
        <Row justifyContent='start'>
          <Column>
            {isSuggestionsError || isSuggestionsLoading ? (
              <>
                <Container
                  isWrap
                  padding='0 8px'
                  width='100%'
                >
                  <Spacer.Horizontal size={12} />
                  <CategoriesBarBackbone />
                </Container>
              </>
            ) : (
              <UpsellingSuggestionPills suggestions={suggestions} />
            )}
          </Column>
        </Row>
        {/* product shelves */}
        {isProductsError || isProductsLoading ? (
          <Container
            id={getBemId('upsellingProductListViewWrapper', 'backbone-shelves')}
            isWrap
          >
            <Spacer.Horizontal customSize={25} />
            <BackboneShelves shelvesForRow={2} />
          </Container>
        ) : (
          <>
            <Spacer.Horizontal size={12} />
            <UpsellingShelves products={products} />
          </>
        )}
      </Container>
    </>
  )
}
