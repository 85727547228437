import {
  Column,
  Picture,
  Row,
  Spacer,
  Text
} from '@smu-chile/pkg-unimarc-components'
export interface PropsCard {
  number: string
  finalUrl: string
}

export const GiftCardPayment = ({
  finalUrl,
  number
}: PropsCard): React.ReactElement => {
  return (
    <>
      <Row alignItems='center'>
        <Column customWidth='15'>
          <Spacer.Horizontal size={2} />
          <Row alignItems='center'>
            <Picture
              height='24px'
              src={finalUrl}
              width='auto'
            />
          </Row>
        </Column>
        <Column>
          <Spacer.Horizontal size={8} />
          <Text
            fontSize='md'
            fontWeight='semibold'
          >
            Giftcard
          </Text>
          <Spacer.Vertical size={8} />
          <Text
            color='gray'
            fontSize='sm'
            isFlex={false}
            textAlign='justify'
          >
            {number}
          </Text>
        </Column>
      </Row>
    </>
  )
}
