import React from 'react'
import {
  Container,
  Horizontal,
  Row,
  Text
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  getGlobalStyle,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'
import { convertToPascalCase } from 'shared/helpers/convertToPascalCase'

export interface NameProps {
  hideQuantity?: boolean
  measurementUnit: string
  name: string
  quantity: number
  unitMultiplier: number
  weight?: number
}

export const validateDecimal = (value: number) => {
  const regex = /^\d{1,2}(\.\d{1,2})?$/
  if (regex.test(value.toString())) {
    return false
  }
  return true
}

export const conditionalDecimal = (
  existWeight: boolean,
  quantity: number,
  unitMultiplier: number,
  weight: number
) => {
  let unitFinal: string | number
  if (existWeight) {
    unitFinal = weight
  }
  if (!existWeight) {
    unitFinal = validateDecimal(quantity * unitMultiplier)
      ? (quantity * unitMultiplier).toFixed(2).replace(/\.0+$/, '')
      : quantity * unitMultiplier
  }
  return unitFinal
}

export const Name = ({
  hideQuantity = false,
  measurementUnit = '',
  name,
  quantity,
  unitMultiplier,
  weight
}: NameProps): React.ReactElement<NameProps> => {
  const { isMobile } = useMobile()
  const existWeight = weight ? true : false
  const unitFinal: string | number = conditionalDecimal(
    existWeight,
    quantity,
    unitMultiplier,
    weight
  )
  const newName =
    name == null
      ? 'Producto sin nombre y descripción'
      : convertToPascalCase(name)
  const truncateName = isMobile ? 55 : 68

  return (
    <>
      <BigScreen>
        <Container isWrap>
          <Row maxWidth='259px'>
            <Text
              color='black'
              fontSize='md'
              truncate={truncateName}
            >
              {newName}
            </Text>
          </Row>
          <Horizontal size={8} />
          <Row>
            {!hideQuantity && (
              <Text
                customColor={getGlobalStyle('--color-neutral-gray-dark')}
                fontSize='sm'
              >
                Cantidad: {unitFinal} {measurementUnit}
              </Text>
            )}
          </Row>
        </Container>
      </BigScreen>

      <SmallScreen>
        <>
          <Row width='80%'>
            <Text
              color='black'
              fontSize='md'
              truncate={truncateName}
            >
              {newName}
            </Text>
          </Row>
        </>
      </SmallScreen>
    </>
  )
}
