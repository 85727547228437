import React from 'react'
import {
  Container,
  Picture,
  Row,
  Spacer,
  Text
} from '@smu-chile/pkg-unimarc-components'
import {
  getBemId,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import { PAYMENT_WEB_PAY_NAMES } from 'shared/helpers/paymentSystems'
import { TextPostPicking } from '../TextPostPicking'

interface WebPayPaymentProps {
  paymentName: string
  paymentImage: string
}

export const WebPayPayment = ({
  paymentName,
  paymentImage
}: WebPayPaymentProps): React.ReactElement => {
  let type = ''

  switch (paymentName) {
    // WEBPAY
    case PAYMENT_WEB_PAY_NAMES[0]: // 'Webpay Plus'
      // no explicit type
      break
    // WEBPAY ONE CLICK CREDITO
    case PAYMENT_WEB_PAY_NAMES[1]: // 'OneClick Crédito'
      type = 'Crédito'
      break
    // WEBPAY ONE CLICK DEBITO
    case PAYMENT_WEB_PAY_NAMES[2]: // 'OneClick Débito'
      type = 'Débito'
      break
    default:
      break
  }

  return (
    <Container
      isWrap
      padding='10px 0px'
    >
      <Row alignItems='center'>
        <Container>
          <Row
            alignItems='center'
            id={getBemId('WebPayPayment', 'Picture')}
          >
            <Picture
              height='24px'
              src={paymentImage}
              width='auto'
            />
            <Spacer.Vertical size={8} />
            {type && <Text fontWeight='medium'>{type}</Text>}
          </Row>
        </Container>
      </Row>

      <SmallScreen>
        {paymentName === PAYMENT_WEB_PAY_NAMES[0] && (
          <>
            <Spacer.Horizontal size={16} />
            <Row>
              <TextPostPicking />
            </Row>
          </>
        )}
      </SmallScreen>
    </Container>
  )
}
