import { Spacer, SuggestionsPills } from '@smu-chile/pkg-unimarc-components'

interface IUpsellingSuggestionPillsProps {
  isMobile?: boolean
  suggestions: string[]
  queryString?: string
  onClickSuggestion?: (suggestion: string) => void
}
export const UpsellingSuggestionPills = ({
  suggestions,
  onClickSuggestion
}: IUpsellingSuggestionPillsProps): React.ReactElement => {
  return (
    <>
      <Spacer.Horizontal size={8} />
      <SuggestionsPills
        isMobile={true}
        onClickSuggestion={onClickSuggestion}
        queryString={'hola'}
        suggestions={suggestions}
      />
    </>
  )
}
