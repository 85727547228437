import { IProductsSearchResponse } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IProductsSearch'

/**
 * Extracts and combines available and not available products from a search response, and parses the total count from the `resource` field.
 *
 * @param {IProductsSearchResponse} data - The response data from the product search.
 * @returns {{ newProducts: unknown[]; totalCount: number }} An object containing the combined list of products and the parsed total count.
 *
 * @example
 * const response = {
 *   availableProducts: [{ name: 'Product 1' }],
 *   notAvailableProducts: [{ name: 'Product 2' }],
 *   resource: '42'
 * }
 * const result = extractProductsAndCount(response)
 * console.log(result)
 * // { newProducts: [{ name: 'Product 1' }, { name: 'Product 2' }], totalCount: 42 }
 */
export const extractProductsAndCount = (data: IProductsSearchResponse) => {
  const {
    availableProducts = [],
    notAvailableProducts = [],
    resource: count
  } = data || {}

  const newProducts = [...availableProducts, ...notAvailableProducts]
  const totalCount = count ? parseInt(count) : Infinity

  return { newProducts, totalCount }
}
