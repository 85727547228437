import { IItemOrder } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IGetOrders'
import { ItemsSent } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IOrder'

export const getTotalQuantityProducts = (items?: IItemOrder[]): number => {
  let quantityProducts = 0

  if (items.length === 0 || !items) return quantityProducts

  items?.map((item: IItemOrder) => {
    if (item.measurementUnit !== 'kg') {
      quantityProducts += item.quantity
    } else {
      quantityProducts += 1
    }
  })
  return quantityProducts
}
