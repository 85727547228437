export const PAYMENT_SYSTEMS = {
  WEB_PAY_PLUS: '30',
  DEBIT: '204',
  CREDIT: '202',
  GIFT_CARD: '16',
  AMEX: '202',
  BANK_STATE: '209'
}

export const PAYMENT_UNIPAY = ['206', '207', '210', '208'] // Se agregan nuevos códigos de Unipay

export const PAYMENT_CARD_TYPE = [
  'redcompra', // 204 - Redcompra con número
  'prepago', // 204 - Prepago con número
  'mastercard', // 202, 203, 205 - Mastercard con número
  'visa', // 202, 203, 205 - Visa con número
  'americanexpress', // 202 - AMEX con número
  'Unipay', // 206 - Unipay sin cuotas
  'Unipay con cuotas', // 207, 210 - Unipay con cuotas
  'Unipay sin cuotas', // 208 - Unipay sin cardNumber
  'Unipay con cuota', // 207, 210 - Unipay con cuotas
  'Unipay sin cuota' // 208 - Unipay sin cardNumber
]

export const PAYMENT_WEB_PAY_NAMES = [
  'Webpay Plus', // 30 - WebPay Plus
  'OneClick Crédito', // Webpay OneClick Crédito
  'OneClick Débito' // Webpay OneClick Débito
]

export const KLAP_PAY_NAMES = [
  'Klap Debito',
  'Klap Débito',
  'Klap Credito',
  'Klap Crédito'
]

export const PAYMENT_GIFT_CARD_NAME = [
  'GiftCard', // 16 - Giftcard
  'Vale'
]

export const BANK_STATE_PAYMENT = [
  'Banco Estado', // 209 - BancoEstado con número
  'Banco Estado sin cardNumber' // 207, 208 - BancoEstado sin número
]
