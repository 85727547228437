import { useMemo } from 'react'
import { useMobile, useProductsCoupon } from '@smu-chile/pkg-unimarc-hooks'
import { ShelvesData } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IOrderForm'
import { IUpsellingShelvesProps } from '../..'
import {
  getMeasurementUnit,
  getToFixedValue,
  getShowDecimals,
  getFormattedQuantity,
  getStockStatus,
  getButtonStatus,
  getFormat,
  getMaxQuantity,
  getParsedQuantity,
  formatTooltipText
} from '../../helpers'
type TUseUpsellingShelvesProps = IUpsellingShelvesProps

export const useUpsellingShelves = ({
  products
}: TUseUpsellingShelvesProps) => {
  const { innerWidth } = useMobile()
  const isMobile = innerWidth < 1289

  const handleAdd = (props, promoId?) => {
    // handleAddFromHook(props)
    // addToPromotion(promoId, 1)
  }

  const handleOnClick = (props, promoId?) => {
    // handleOnClickFromHook(props)
    // addToPromotion(promoId, 1)
  }

  const handleRemove = (props, promoId?) => {
    // handleRemoveFromHook(props)
    // addToPromotion(promoId, -1)
  }

  const validateProps = ({
    item,
    quantity,
    unitMultiplier
  }: {
    item: ShelvesData
    quantity: number
    unitMultiplier: number
  }) => {
    const measurementUnit = getMeasurementUnit(item?.measurementUnit)
    const toFixedValue = getToFixedValue(item?.measurementUnit)
    const showDecimals = getShowDecimals(quantity, unitMultiplier, toFixedValue)
    const showQuantity = getFormattedQuantity(
      quantity,
      unitMultiplier,
      showDecimals,
      measurementUnit
    )

    const seller = item?.sellers?.[0] ?? {}
    const sellerPrice =
      typeof seller?.price === 'number' ? seller.price : parseInt(seller.price)
    const isStock = getStockStatus(sellerPrice, seller.availableQuantity)
    const buttonStatus = getButtonStatus(isStock)

    return {
      maxQuantity: getMaxQuantity(item?.cartLimit, seller.availableQuantity),
      measurementUnit,
      showQuantity,
      stock: isStock,
      format: getFormat(
        item?.measurementUnit,
        item?.unitMultiplier,
        item?.format
      ),
      buttonStatus
    }
  }

  const productsWithoutCoupons = useMemo(() => {
    if (!products) return []

    return products.map((item: ShelvesData): ShelvesData => {
      const quantity = getParsedQuantity(item.quantity)
      const unitMultiplier = item.unitMultiplier

      const { maxQuantity, ...propsShelf } = validateProps({
        item,
        quantity,
        unitMultiplier
      })

      return {
        ...item,
        ...propsShelf,
        tooltipProps: {
          text: formatTooltipText(quantity, maxQuantity),
          left: isMobile ? '30px' : 'initial',
          maxWidth: 'initial'
        },
        quantityButtonProps: {
          maxQuantity,
          quantity
        },
        quantity: propsShelf.showQuantity
      }
    })
  }, [products, isMobile])

  const { products: shelvesData } = useProductsCoupon(productsWithoutCoupons)

  return {
    isMobile,
    shelvesData,
    shelvesHandlers: { handleAdd, handleOnClick, handleRemove }
  }
}
